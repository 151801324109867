import { useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { ALLOWED_FILE_TYPES, ErrorList, Screens } from "../../components/Enums";

const useAds = (token) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const config = {
        signal: controller.signal,
        headers: {
            Authorization: "Bearer " + token
        }
    };

    useEffect(() => {
        return () => {
            controller.abort();
            isMountedRef.current = false;
        };
    }, []);


    async function requestS3PresignedUrl(fileType, abortController) {
        try {
            // request S3 presigned Url
            const signal = abortController ? abortController.signal : undefined;
            if (ALLOWED_FILE_TYPES.IMAGE.includes(fileType)) {
                const response = await axios.get(`${API_URL}/advertising/generateImageUrl/${fileType}`, config, signal);
                const s3PresignedUrl = response.data.url;
                return s3PresignedUrl;
            }
            if (ALLOWED_FILE_TYPES.VIDEO.includes(fileType)) {
                const response = await axios.get(`${API_URL}/advertising/generateVideoUrl/${fileType}`, config, signal);
                const s3PresignedUrl = response.data.url;
                return s3PresignedUrl;
            }
            return "Failed to retrieve url";
        } catch (e) {
            if (axios.isCancel(e)) {
                console.warn("Request canceled:", e.message);
            } else {
                console.warn("Unable to request presigned URL for ad.", e);
            }
            return "";
        }
    }

    async function uploadToS3(s3PresignedUrl, fileObject, abortController) {
        try {
            const signal = abortController ? abortController.signal : undefined;
            // upload image to S3
            await axios.put(s3PresignedUrl, fileObject, {
                headers: { "Content-Type": fileObject.type },
                signal,
            });

            return s3PresignedUrl.split("?")[0];
        } catch (e) {
            if (axios.isCancel(e)) {
                console.warn("Upload canceled:", e.message);
            } else {
                console.warn("Unable to upload ad asset.", e);
            }
            return "";
        }
    }

    const dataURLtoFile = (dataUrl, filename) => {
        const [meta, content] = dataUrl.split(",");
        const mime = meta.match(/:(.*?);/)[1];
        const binary = atob(content);
        const u8arr = new Uint8Array(binary.length);
    
        for (let i = 0; i < binary.length; i++) {
            u8arr[i] = binary.charCodeAt(i);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const validateAd = (ad, primaryAsset) => {
        const errors = [];

        if (!ad.headline) {
            errors.push(ErrorList.EMPTY_HEADLINE);
        }
        if (!ad.description) {
            errors.push(ErrorList.EMPTY_DESCRIPTION);
        }
        if (!ad.audience) {
            errors.push(ErrorList.EMPTY_AUDIENCE);
        }
        if (!ad.placement || ad.placement.length === 0) {
            errors.push(ErrorList.EMPTY_PLACEMENT);
        }
        if (!ad.buttonText) {
            errors.push(ErrorList.EMPTY_BUTTON_TEXT);
        }
        if (!ad.buttonLink) {
            errors.push(ErrorList.EMPTY_BUTTON_LINK);
        }

        if (ad.startsAt && ad.endsAt && ad.startsAt > ad.endsAt) {
            errors.push(ErrorList.DATE_RANGE_INVALID);
        }
        if (!ad.startsAt) {
            errors.push(ErrorList.EMPTY_START_DATE);
        } else {
            if (isNaN(new Date(ad.startsAt))) {
                errors.push(ErrorList.DATE_START_DATE_INVALID);
            }
        }
    
        if (!ad.endsAt) {
            errors.push(ErrorList.EMPTY_END_DATE);
        } else {
            if (isNaN(new Date(ad.endsAt))) {
                errors.push(ErrorList.DATE_END_FORMAT_INVALID);
            }
        }

        if (!primaryAsset) {
            errors.push(ErrorList.EMPTY_PRIMARY_ASSET);
        }
        return errors;
    };

    const screenSwitch = (param) => {
        switch (param) {
            case Screens.QUOTES:
                return "Quote Screen";
            case Screens.FULLSCREEN_QUOTE:
                return "Fullscreen Quote";
            case Screens.NOTICEBOARD:
                return "Noticeboard Screen";
            default:
                return "Quote Screen";
        }
    };

    return { 
        requestS3PresignedUrl,
        uploadToS3,
        dataURLtoFile,
        validateAd,
        screenSwitch
    };
};
export default useAds;