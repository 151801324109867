import React, { Component } from "react";
import { LicenseRenewalContainerContext } from "src/components/LicenseRenewalContainerContext";
import { LicenseRenewalSubscriptionContext } from "./LicenseRenewalSubscriptionContext";
import LicenseRenewalSuccess from "./components/LicenseRenewalSuccess";
import PaymentConfirmation from "./components/PaymentConfirmation";
import styled from "styled-components";
import { Text, FlexContainer, Button } from "src/components";

const GetButton = styled(Button)`
    padding: 15px 30px 15px !important;
    border-radius: 13px !important;
    color: #FFFFFF;
    position: relative;
    cursor: pointer;
    width:210px;
    font-weight:bold;
    font-size: 1em;
`;

export const FormStep = {
    PAGE_PURCHASE_LICENSE: 1,
    PAGE_PAYMENT_CONFIRMATION: 2,
    PAGE_PURCHASE_SUCCESSFUL: 3
};

class LicenseRenewal extends Component {
    static contextType = LicenseRenewalContainerContext;

    state = {
        purchaseStep: FormStep.PAGE_PURCHASE_LICENSE,
        purchaseLicenseLoading: false,
        licenseCount: 1,
    };

    nextStep = (stepNumber) => {
        this.setState({ 
            purchaseStep: stepNumber
        });
    };

    render() {
        const { licenseTermDuration, licenseTermDurationUnit, licenseTermAmount, isAustralian, gstAmount, currency, chargeAmount, accessToken, key, referralCode, employeeName, businessName, licenseStatus, finishDate } = this.context;
        const appName = <span><strong>{businessName} - ME</strong> App</span>;

        const durationNiceString = licenseTermDuration + " " + licenseTermDurationUnit.charAt(0).toUpperCase() + licenseTermDurationUnit.slice(1);
        
        if (this.state.purchaseStep === FormStep.PAGE_PURCHASE_SUCCESSFUL)
            return <LicenseRenewalSuccess />;

        if (this.state.purchaseStep === FormStep.PAGE_PAYMENT_CONFIRMATION) {
            return (
                <FlexContainer style={{ margin: "auto 0" }}>
                    <LicenseRenewalSubscriptionContext.Provider value={{
                        //global
                        nextStep: this.nextStep,
                        referralCode: referralCode,
                        key: key,
                        companyName: businessName,
                        licenseTermDuration: licenseTermDuration,
                        licenseTermAmount: licenseTermAmount,
                        licenseTermDurationUnit: licenseTermDurationUnit,
                        isAustralian: isAustralian,
                        gstAmount: gstAmount,
                        currency: currency,
                        chargeAmount: chargeAmount
                    }}>
                        <PaymentConfirmation />
                    </LicenseRenewalSubscriptionContext.Provider>
                </FlexContainer>
            );
        }

        const expiryMessage = () => {
            return licenseStatus !== "active"
                ? (<>We would like to inform you that your trial license for the {appName} has reached its expiration date</>)
                : (<>We would like to inform you that your license for the {appName} is due to expire on {finishDate}</>);
        };

        return (
            <FlexContainer style={{ margin: "auto 0" }}>
                <FlexContainer alignItems="center" padding="2rem 1rem" height="100%">
                    <Text size="40px" color="#000" weight="500">
                        Renew License
                    </Text>

                    <Text margin="10px" size="16px" color="#000" weight="500">
                        Dear {employeeName},
                    </Text>

                    <Text margin="10px" size="16px" color="#000" weight="500" width="35rem" >
                        {expiryMessage()}.
                    </Text>
                    
                    <Text margin="10px" size="16px" color="#000" weight="500" width="35rem">
                        In order to sustain your continued usage of the {appName}, 
                        we kindly request that you purchase a {durationNiceString}, 
                        thereby ensuring uninterrupted access to the {appName}.
                    </Text>

                    <Text margin="10px" size="16px" color="#000" weight="500">
                        Thank you
                    </Text>

                    <Text margin="20px 0 0 0" size="60px" color="#000" weight="bold">
                        {currency} ${licenseTermAmount}
                    </Text>

                    <Text margin="0px 0px 50px 0px" size="20px" color="#000" weight="500">
                        {durationNiceString} License
                    </Text>

                    <Text size="20px" color="#000" weight="bolder">
                        Your App Access Code Is:
                    </Text>

                    <FlexContainer style={{ margin: "auto", width: "210px", paddingBottom: "4rem" }}>
                        <Text margin="0 0 80px 0" size="20px">
                            <div style={{ color: "#2A2B2A", fontWeight: "bolder", letterSpacing: "1px", padding: "18px 30px 18px 30px", borderRadius: "17px", border: "1px solid #2D3037" }}>
                                {accessToken}
                            </div>
                        </Text>

                        <GetButton backgroundColor="#006CFF" onClick={(e) => {this.nextStep(FormStep.PAGE_PAYMENT_CONFIRMATION);}} >Renew License</GetButton>
                    
                    </FlexContainer>
                    
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default LicenseRenewal;