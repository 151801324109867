import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Dialog, Switch, withStyles } from "@material-ui/core";
import { FlexContainer, Text, Button, Toast } from "src/components";
import { Grid } from "@material-ui/core";
import SearchIcon from "src/img/new/search-gray.svg";
import DropdownIcon from "src/img/new/drop-down.svg";
import UsersIcon from "src/img/new/users.svg";
import CloseIcon from "src/img/CloseButton@2x.png";
import { USER_GROUP } from "src/constants/perks";
import getUsers from "../hooks/getUsers";
import { generateNameInitials } from "src/utils/helpers";
import BlueCheck from "src/img/survey/blue-check.svg";

const ModalContainer = styled.div`
    display: flex,
    flex-direction: column,
    height: 100%;
`;

const Header = styled(FlexContainer)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f5f8;
    height: 5.125rem;
    padding: 0 2.375rem 0 1.6875rem;
`;

const SelectInput = styled.select`
    border: 1px solid #dbe5ed;
    border-radius: 0.438rem;
    padding-left: 1.25rem;
    width: 12rem;
    height: 2.563rem;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position: right 0.438rem center;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    color: #4b5155;
    appearance: none;

    &:focus {
        outline: none;
    }
`;

const RoundedIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    width: 2.75rem;
    background-color: #D6D6D6;
    border: ${p => p.border || "2px solid #fff"};
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 10px;
    color: #6f6f6f;
`;

const SelectedUsersContainer = styled.div`
    width: 15.125rem;
    height: 3.688rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.938rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.938rem;
    box-shadow: 0px 5px 5px #0000001A;
    margin-left: ${p => p.marginLeft || "none"}
`;

const StyledButton = styled(Button)`
    width: 10.313rem;
    height: 3rem;
    border-radius: 0.625rem !important;
    font-size: 0.938rem;
    font-weight: 500;
    box-sizing: border-box;
    background-color: ${ p => p.backgroundColor || "" };
    color: ${ p => p.color || "" };
`;

const ToggleContainer = styled.div`
    height: 2.5rem;
    background-color: #fff;
    border: 1px solid #dbe5ed;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SearchInput = styled.input`
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: right 0.625rem center;
    line-height: 100%;
    width: 100%;
    border: 1px solid #dbe5ed;
    height: 2.5rem;
    border-radius: 8px;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0 0 1rem;
    box-sizing: border-box;
    ::placeholder {
        text-align: left;
        font-weight: normal;
        font-size: 0.875rem;
        color: #afbbc6;
    }

    &:focus {
        outline: none;
    }
`;

const TableContainer = styled.div`
    height: 30.75rem;
    overflow-y: scroll;
    margin: 10px 0 30px;
`;

const StyledTable = styled.table`
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background-color: #fff;

    thead {
        background-color: #f9fafc;
    }

    th {
        font-size: 1rem;
        color: #4b5155;
        padding: 0.75rem 0.75rem 1.125rem;
    }

    th:last-child {
        text-align: center;
    }

    td {
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 500;
        border: solid 1px #DBE5ED;
        border-style: none solid solid none;
        padding: 0.75rem;
    }

    tr:first-child td:first-child {
        border-top-left-radius: 8px;
    }

    tr:first-child td:last-child {
        border-top-right-radius: 8px;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
    }

    tr:first-child td {
        border-top-style: solid;
    }

    tr td:first-child {
        border-left-style: solid;
        display: flex;
        align-items: center;
    }

    tr td:last-child {
        text-align: center;
    }
`;

const SelectButton = styled.div`
    height: 1.625rem;
    width: 1.625rem;
    border: 1px solid #707070;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
`;

const BlueSwitch = withStyles((theme) => ({
    root: {
        width: 38,
        height: 25,
        padding: 0,
        margin: 12,
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: "#1a61ff",
                opacity: 1,
                border: "none",
                height: 22
            },
            "$ + $thumb": {
                width: 21,
                height: 21
            }
        },
        "&$focusVisible $thumb": {
            color: "#1a61ff",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 20,
        height: 20,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: "#c9c9c9",
        height: 20,
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const UsersModal = ({
    user,
    showUsersModal,
    handleCloseUsersModal,
    handleGiftPerkRecipients,
    userType,
    selectedRecipients
}) => {
    const [filterType, setFilterType] = useState(USER_GROUP.EMPLOYEES);
    const { users, isFetchingUsers } = getUsers(user.token, filterType);
    const [searchQuery, setSearchQuery] = useState("");
    const [allEmployeesInPerk, setAllEmployeesInPerk] = useState(false);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [allClientsInPerk, setAllClientsInPerk] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);

    // Set users table filter on edit
    useEffect(() => {
        if (userType) {
            setFilterType(userType);
        }
    }, [userType]);

    // Set selected users on editing gift perk users
    useEffect(() => {
        const clients = [];
        const employees = [];

        selectedRecipients.forEach(user => {
            if (user.isClient) {
                clients.push(user);
            } else {
                employees.push(user);
            }
        });

        setSelectedClients(clients);
        setSelectedEmployees(employees);
    }, [selectedRecipients]);

    // Pre-select `allEmployeesInPerk` & `allClientsInPerk`
    useEffect(() => {
        if (!isFetchingUsers && users.length > 0) {
            const totalEmployees = users.filter((user) => !user.isClient).length;
            const totalClients = users.filter((user) => user.isClient).length;

            setAllEmployeesInPerk(selectedEmployees.length === totalEmployees);
            setAllClientsInPerk(selectedClients.length === totalClients);
        }
    }, [isFetchingUsers, users, selectedEmployees, selectedClients]);

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const handleFilterType = e => {
        setFilterType(e.target.value);
    };

    const handleSelectAll = () => {
        if (filterType === USER_GROUP.EMPLOYEES) {
            const isChecked = !allEmployeesInPerk;
            setAllEmployeesInPerk(isChecked);

            if (isChecked) {
                const employees = users.filter(user => user.isClient === false);
                setSelectedEmployees(employees);
            } else {
                setSelectedEmployees([]);
            }
        } else if (filterType === USER_GROUP.CLIENTS) {
            const isChecked = !allClientsInPerk;
            setAllClientsInPerk(isChecked);

            if (isChecked) {
                const clients = users.filter(user => user.isClient === true);
                setSelectedClients(clients);
            } else {
                setSelectedClients([]);
            }
        }
    };

    const handleSelectUser = user => {
        if (filterType === USER_GROUP.EMPLOYEES) {
            setSelectedEmployees(prevSelectedEmployees => {
                const updatedEmployees = prevSelectedEmployees.some(selectedUser => selectedUser.employeeUuid === user.employeeUuid)
                    ? prevSelectedEmployees.filter(selectedUser => selectedUser.employeeUuid !== user.employeeUuid)
                    : [...prevSelectedEmployees, user];

                const employeesCount = users.filter(user => !user.isClient).length;
                setAllEmployeesInPerk(updatedEmployees.length === employeesCount);

                return updatedEmployees;
            });
        } else if (filterType === USER_GROUP.CLIENTS) {
            setSelectedClients(prevSelectedClients => {
                const updatedClients = prevSelectedClients.some(selectedUser => selectedUser.employeeUuid === user.employeeUuid)
                    ? prevSelectedClients.filter(selectedUser => selectedUser.employeeUuid !== user.employeeUuid)
                    : [...prevSelectedClients, user];

                const clientsCount = users.filter(user => user.isClient).length;
                setAllClientsInPerk(updatedClients.length === clientsCount);

                return updatedClients;
            });
        }
    };

    const handleSaveRecipients = () => {
        const allSelectedUsers = [...selectedEmployees, ...selectedClients];
        if (!allSelectedUsers.length) {
            Toast.error("Please select some users.");
            return;
        }
        handleGiftPerkRecipients(allSelectedUsers);
        handleCloseUsersModal();
    };

    const isUserSelected = (user) => {
        return filterType === USER_GROUP.EMPLOYEES ?
            selectedEmployees.some(selectedUser => selectedUser.employeeUuid === user.employeeUuid) :
            selectedClients.some(selectedUser => selectedUser.employeeUuid === user.employeeUuid);
    };

    const filteredUsers = users.filter(user => {
        const { firstName, lastName } = user;
        const searchTerms = `${firstName.toLowerCase()} ${lastName.toLowerCase()}`;
        const isMatchingSearch = searchTerms.includes(searchQuery.toLowerCase());

        if (!isMatchingSearch) {
            return false;
        }

        return filterType === USER_GROUP.EMPLOYEES ? !user.isClient : user.isClient;
    });

    const selectedCount = filterType === USER_GROUP.EMPLOYEES ? selectedEmployees.length : selectedClients.length;

    const selectedType = filterType.charAt(0).toUpperCase() + filterType.slice(1);

    return (
        <Dialog
            open={showUsersModal}
            className="scroll-design"
            maxWidth="md"
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: "100%",
                    height: "auto",
                    borderRadius: "1.25rem",
                    overflow: "auto",
                    background: "#f9fafc"
                }
            }}
        >
            <ModalContainer>
                <Header>
                    <Text
                        color="#4b5155"
                        size="1.125rem"
                        weight="700"
                    >
                        Select Gift Perk Users
                    </Text>

                    <SelectInput value={filterType} onChange={handleFilterType} disabled={isFetchingUsers}>
                        <option value={USER_GROUP.EMPLOYEES}>Employees</option>
                        <option value={USER_GROUP.CLIENTS}>Clients</option>
                    </SelectInput>
                </Header>

                <Box
                    sx={{
                        padding: "0 3.0625rem",
                        display: "flex",
                        alignItems: "center",
                        background: "#fff",
                        height: "90px"
                    }}
                >
                    <Text
                        color="#4b5155"
                        size="1rem"
                        weight="700"
                        align="left"
                        style={{ marginRight: "1.25rem" }}
                    >
                        Selected People:
                    </Text>

                    { !isFetchingUsers && (
                        <>
                            { selectedEmployees.length > 0 && (
                                <SelectedUsersContainer>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <RoundedIconContainer>
                                            <img src={UsersIcon} />
                                        </RoundedIconContainer>

                                        <Text
                                            size="1rem"
                                            weight="500"
                                            color="#000"
                                        >
                                            { allEmployeesInPerk ? "All" : selectedEmployees.length } { selectedEmployees.length === 1 ? "Employee" : "Employees" }
                                        </Text>
                                    </div>

                                    <img src={CloseIcon} style={{ height: "1.375rem", width: "1.375rem", cursor: "pointer" }} />
                                </SelectedUsersContainer>
                            )}

                            { selectedClients.length > 0 && (
                                <SelectedUsersContainer style={{ marginLeft: selectedEmployees.length > 0 ? "0.625rem" : "0" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <RoundedIconContainer>
                                            <img src={UsersIcon} />
                                        </RoundedIconContainer>

                                        <Text
                                            size="1rem"
                                            weight="500"
                                            color="#000"
                                        >
                                            { allClientsInPerk ? "All" : selectedClients.length } { selectedClients.length === 1 ? "Client" : "Clients" }
                                        </Text>
                                    </div>

                                    <img src={CloseIcon} style={{ height: "1.375rem", width: "1.375rem", cursor: "pointer" }} />
                                </SelectedUsersContainer>
                            )}
                        </>
                    )}
                </Box>

                <Box sx={{ padding: "0.875rem 3.0625rem" }}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item md={9}>
                            <Text
                                color="#4b5155"
                                size="1rem"
                                weight="700"
                                align="left"
                            >
                                { `Select ${selectedType}` }
                            </Text>

                            <SearchInput
                                placeholder={`Search ${filterType}`}
                                type="text"
                                onChange={handleSearchChange}
                                disabled={isFetchingUsers}
                            />
                        </Grid>

                        <Grid item md={3}>
                            <Text
                                color="#000"
                                size="1rem"
                                weight="700"
                                align="right"
                            >
                                { `${ isFetchingUsers ? 0 : selectedCount} ${selectedType} Selected` }
                            </Text>

                            <ToggleContainer>
                                <Text
                                    size="0.875rem"
                                    color="#000"
                                    weight="500"
                                    style={{ marginLeft: "0.938rem" }}
                                >
                                    { `All ${selectedType}` }
                                </Text>

                                <BlueSwitch
                                    onChange={handleSelectAll}
                                    checked={filterType === USER_GROUP.EMPLOYEES ? allEmployeesInPerk : allClientsInPerk}
                                    disabled={isFetchingUsers}
                                />
                            </ToggleContainer>
                        </Grid>
                    </Grid>

                    <TableContainer>
                        <StyledTable>
                            <thead>
                                <tr>
                                    <th width="25%">Name</th>
                                    <th width="25%">Department / Group</th>
                                    <th width="25%">Job Title</th>
                                    <th width="25%">Select User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map((user, index) => {
                                    const nameInitials = generateNameInitials(user.firstName, user.lastName);
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <RoundedIconContainer border="1px solid #fff">{nameInitials}</RoundedIconContainer> {user.employeeName}
                                            </td>
                                            <td>{user.department}</td>
                                            <td>{user.employmentPosition}</td>
                                            <td>
                                                {isUserSelected(user) ?
                                                    <img src={BlueCheck} style={{ cursor: "pointer" }} height={26} width={26} onClick={() => handleSelectUser(user)} />
                                                    :
                                                    <SelectButton onClick={() => handleSelectUser(user)} />
                                                }
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </StyledTable>
                    </TableContainer>

                    <FlexContainer
                        direction="row"
                        justifyContent="flex-end"
                        bottom="0.5rem"
                        height="3.5rem"
                        right="2rem"
                        marginBottom="0.625rem"
                    >
                        <StyledButton
                            backgroundColor="#fff"
                            color="#afbbc6"
                            border="1px solid #dbe5ed"
                            onClick={handleCloseUsersModal}
                        >
                            Cancel
                        </StyledButton>

                        <StyledButton
                            backgroundColor="#006cff"
                            color="#fff"
                            marginLeft="1.3125rem"
                            onClick={handleSaveRecipients}
                            disabled={isFetchingUsers}
                        >
                            Save
                        </StyledButton>
                    </FlexContainer>
                </Box>
            </ModalContainer>
        </Dialog>
    );
};

export default UsersModal;