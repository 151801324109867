import React, { useEffect, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios"; 
import Select from "react-select";
import styled from "styled-components";

import {
    FlexContainer, FormField, ErrorText,
    Text, Form, PageHeadingLabel, PageContainer,
    Checkbox,
    Toast
} from "src/components";

import { CancelButton, SaveButton, StyledButton, QuestionContainer,
    AddAnotherQuestionContainer, FormBackground, FormSectionHeader,
    LoadingIndicatorButton, LoadingIndicatorIcon, EditQuestionContainer
} from "../components/Utils";
import { EnumSurveyShowFrequency, EnumSurveyShownIn, ErrorStrings,
    ErrorList, FormStep, surveyTypeOptions,
    surveyShowInOptions, surveyFrequencyOptions,
    EnumUserGroup
} from "../components/Enums";
import { customStyles, IconOption, SingleValue, customDisabledStyles } from "./components/DropdownCustomStyles";
import { SurveyStatusType } from "src/constants/survey";
import ValidatedDateInput from "src/components/ValidatedDateInput";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import PollVisibilitySection from "./components/PollVisibilitySection";
import UserGroup from "src/img/survey/updatedIcons/users.svg";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const DisabledStyledCheckbox = styled.div`
  background-clip: content-box;
  background-color: ${props => (props.checked ? props.color || "#85b336" : "white")};
  border: ${p => p.border || "1px solid #2D3037"};
  box-sizing: border-box;
  height: ${p => p.height || "20px"};
  padding: ${p => p.padding || "5px"};
  border-radius: ${ p => p.radius || "0"};
  transition: all 150ms;
  width: ${p => p.width || "20px"};
  cursor: not-allowed;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`;

const SurveyForm = (props) => {
    const { user, surveyToEdit, showSurveyForm, getSurveys, selectedSurveyOrPollStatusType } = props;
    const [survey, setSurvey] = useState({
        name: "",
        surveyType: "",
        startDate: "",
        finishDate: "",
        userGroup: "",
        surveyQuestions: {
            one: { uuid: "", question: "" },
            two: {},
            three: {}
        },
        showIn: EnumSurveyShownIn.QUOTE_SCREEN,
        showFrequency: EnumSurveyShowFrequency.EVERYTIME,
        isAnonymous: false,
        status: ""
    });
    const [inputErrors, setInputErrors] = useState([]);
    const [formStep, setFormStep] = useState(FormStep.PAGE_SURVEY_NAME);
    const [setInputTypeAs] = useState("text");
    const [activeQuestionCount, setActiveQuestionCount] = useState(1);
    const [formSubmitLoading, setFormSubmitLoading] = useState(false);
    const [surveyInfoLoading, setSurveyInfoLoading] = useState(false);
    const [isEditSurveyForm, setIsEditSurveyForm] = useState(false);
    const [showPollVisibilityModal, setShowPollVisibilityModal] = useState(false);
    const [allEmployeesInSurvey, setAllEmployeesInSurvey] = useState(false);
    const [allClientsInSurvey, setAllClientsInSurvey] = useState(false);
    const [employeesCountInPoll, setEmployeesCountInPoll] = useState(0);
    const [clientsCountInPoll, setClientsCountInPoll] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isShowInitialUserGroupAddButton, setIsShowInitialUserGroupAddButton] = useState(true);

    useEffect(() => {
        if (surveyToEdit) {
            getSurvey(surveyToEdit);
            setSurveyInfoLoading(true);
            setIsEditSurveyForm(true);
        } else {
            setSurveyInfoLoading(false);
            setIsEditSurveyForm(false);
        }
    }, [surveyToEdit]);

    const getSurvey = (surveyUuid) => {
        setSurveyInfoLoading(true);

        axios.get(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(res => {
            const surveyResponse = res.data.survey;
            setSurvey({
                name: surveyResponse.name || "",
                surveyType: surveyResponse.surveyType || "",
                startDate: surveyResponse.startDate || "",
                finishDate: surveyResponse.finishDate || "",
                userGroup: surveyResponse.userGroup || "",
                surveyQuestions: {
                    one: surveyResponse.surveyQuestions[0] || { uuid: "", question: "" },
                    two: surveyResponse.surveyQuestions[1] || { uuid: "", question: "" },
                    three: surveyResponse.surveyQuestions[2] || { uuid: "", question: "" },
                },
                showIn: surveyResponse.showIn || EnumSurveyShownIn.QUOTE_SCREEN,
                showFrequency: surveyResponse.showFrequency || EnumSurveyShowFrequency.EVERYTIME,
                isAnonymous: surveyResponse.isAnonymous || false,
                status: surveyResponse.status || "",
                clientCount: surveyResponse.clientCount,
                employeeCount: surveyResponse.employeeCount,
                totalEmployeeCount: surveyResponse.totalEmployeeCount,
                totalClientCount: surveyResponse.totalClientCount,
                usersSelectedDetails: surveyResponse.usersSelectedDetails,
                usersSelectedUuid: surveyResponse.usersSelectedUuid
            });
            setIsShowInitialUserGroupAddButton(false);
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        }).finally(() => {
            setSurveyInfoLoading(false);
        });
    };

    const updateSurvey = (key, value) => {
        setSurvey(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const inputSurveyName = (event) => {
        updateSurvey("name", event.target.value);
    };

    const inputStartDate = (selectedDate) => {
        updateSurvey("startDate", selectedDate);
    };

    const inputFinishDate = (selectedDate) => {
        updateSurvey("finishDate", selectedDate);
    };

    const selectSurveyType = (event) => {
        updateSurvey("surveyType", event.value);
    };

    const inputQuestion = (questionNumber, event) => {
        const questionKey = ["", "one", "two", "three"][questionNumber];
        const questionString = event.target.value;
        setSurvey(prev => ({
            ...prev,
            surveyQuestions: {
                ...prev.surveyQuestions,
                [questionKey]: { uuid: "", question: questionString, questionOrder: questionNumber }
            }
        }));
    };

    const selectUserGroup = (event) => {
        updateSurvey("userGroup", event.value);
        setInputErrors([]); // reset errors
    };

    const removeQuestion = (questionKey) => {
        setSurvey(prev => ({
            ...prev,
            surveyQuestions: {
                ...prev.surveyQuestions,
                [questionKey]: {}
            }
        }));
        setActiveQuestionCount(prev => prev - 1);
    };

    const addQuestion = () => {
        setActiveQuestionCount(prev => prev + 1);
    };

    const selectShowIn = (event) => {
        updateSurvey("showIn", event.value);
    };

    const setSurveyAnonymous = (event) => {
        updateSurvey("isAnonymous", event);
    };

    const selectShowFrequency = (event) => {
        updateSurvey("showFrequency", event.value);
    };
    
    const validateSurvey = (survey) => {
        const errors = [];
    
        if (!survey.name) {
            errors.push(ErrorList.EMPTY_SURVEY_NAME);
        }
        if (!survey.surveyType) {
            errors.push(ErrorList.EMPTY_SURVEY_TYPE);
        }
        if (!survey.userGroup) {
            errors.push(ErrorList.EMPTY_USER_GROUP);
        }
        if (survey.startDate && survey.finishDate && survey.startDate > survey.finishDate) {
            errors.push(ErrorList.DATE_RANGE_INVALID);
        }
        if (!survey.startDate) {
            errors.push(ErrorList.EMPTY_START_DATE);
        } else {
            if (isNaN(new Date(survey.startDate))) {
                errors.push(ErrorList.DATE_START_DATE_INVALID);
            }
        }
        if (!survey.finishDate) {
            errors.push(ErrorList.EMPTY_FINISH_DATE);
        } else {
            if (isNaN(new Date(survey.finishDate))) {
                errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
            }
        }
        if (survey.userGroup === EnumUserGroup.SELECTED_USERS && selectedUsers.length === 0) {
            errors.push(ErrorList.EMPTY_SELECTED_USERS);
        }

        if (survey.surveyQuestions.one.question === "") {
            errors.push(ErrorList.EMPTY_QUESTION_ONE);
        }

        if (Object.keys(survey.surveyQuestions.two).length === 0 && activeQuestionCount >= 2) {
            errors.push(ErrorList.EMPTY_QUESTION_TWO);
        }

        if (Object.keys(survey.surveyQuestions.three).length === 0 && activeQuestionCount === 3) {
            errors.push(ErrorList.EMPTY_QUESTION_THREE);
        }
    
        return errors;
    };
    
    const createFormSubmission = () => {
        setFormSubmitLoading(true);
    
        const errors = validateSurvey(survey);
        if (errors.length > 0) {
            setInputErrors(errors);
            setFormSubmitLoading(false);
            return;
        }
    
        // Format dates for the request
        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = formatter.format(new Date(survey.startDate)) + " 00:00:00";
        const formattedFinishDate = survey.finishDate ? formatter.format(new Date(survey.finishDate)) + " 23:59:59" : null;
    
        const surveyQuestions = Object.fromEntries(
            Object.entries(survey.surveyQuestions)
                .filter(([_, question]) => question.question)
        );
        let bodyObject = {};
        if (survey.userGroup === EnumUserGroup.SELECTED_USERS) {
            bodyObject = {
                ...survey,
                startDate: formattedStartDate,
                finishDate: formattedFinishDate,
                surveyQuestions,
                selectedUsers: selectedUsers
            };
        } else {
            bodyObject = {
                ...survey,
                startDate: formattedStartDate,
                finishDate: formattedFinishDate,
                surveyQuestions,
            };
        }
    
        // Axios request
        axios.post(`${API_URL}/survey/company`, bodyObject, {
            headers: { Authorization: `Bearer ${user.token}` }
        }).then(() => {
            setFormStep(FormStep.PAGE_CONGRATULATIONS);
        }).catch(error => {
            const serverErrors = [];
            error.fields?.forEach(fieldKey => {
                switch (fieldKey) {
                    case "startDateInvalid":
                        serverErrors.push(ErrorList.DATE_START_DATE_INVALID);
                        break;
                    case "finishDateInvalid":
                        serverErrors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                        break;
                    case "startDateGreaterThanFinishDate":
                        serverErrors.push(ErrorList.DATE_RANGE_INVALID);
                        break;
                    case "name":
                        serverErrors.push(ErrorList.EMPTY_SURVEY_NAME);
                        break;
                    case "surveyType":
                        serverErrors.push(ErrorList.EMPTY_SURVEY_TYPE);
                        break;
                    case "userGroup":
                        serverErrors.push(ErrorList.EMPTY_USER_GROUP);
                        break;
                    case "startDateEmpty":
                        serverErrors.push(ErrorList.EMPTY_START_DATE);
                        break;
                    case "finishDateEmpty":
                        serverErrors.push(ErrorList.EMPTY_FINISH_DATE);
                        break;
                    default:
                        break;
                }
            });
            setInputErrors(serverErrors);
        }).finally(() => setFormSubmitLoading(false));
    };

    const updateFormSubmission = (surveyToEdit) => {
        setFormSubmitLoading(true);
        let errors = validateSurvey(survey);
        
        if (errors.length > 0) {
            setFormSubmitLoading(false);
            setInputErrors(errors);
            return Toast.error("Please make sure your entries are correct");
        }

        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = survey.startDate ? formatter.format(new Date(survey.startDate)) : "";
        const formattedFinishDate = survey.finishDate ? formatter.format(new Date(survey.finishDate)) : "";

        const surveyQuestions = Object.fromEntries(
            Object.entries(survey.surveyQuestions)
                .filter(([_, question]) => question.question)
        );
        let formatedPayload = {
            name: survey.name,
            surveyType: survey.surveyType,
            startDate: survey.startDate ? formattedStartDate + " 00:00:00" : null,
            finishDate: survey.finishDate ? formattedFinishDate + " 00:00:00" : null,
            userGroup: survey.userGroup,
            surveyQuestions: surveyQuestions,
            showIn: survey.showIn,
            showFrequency: survey.showFrequency,
            isAnonymous: survey.isAnonymous
        };

        if (survey.userGroup === EnumUserGroup.SELECTED_USERS) {
            formatedPayload = {
                ...formatedPayload,
                selectedUsers: selectedUsers
            };
        }

        axios.put(`${API_URL}/survey/company/${surveyToEdit}`, formatedPayload,
            {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(res => {
            const surveyResponse = res.data.survey;
            setSurvey(surveyResponse);
            showSurveyForm(false);
            return Toast.info("Survey updated successfully");
        }).catch(error => {
            if (!error.response) {
                return Toast.error(error.message);
            }

            let errors = [];
            let dataErrorFields = error.response.data.error.fields;
            
            dataErrorFields.forEach(function(fieldKey) {
                switch (fieldKey) {
                case "startDateInvalid":
                    errors.push(ErrorList.DATE_START_DATE_INVALID);
                    return;
   
                case "finishDateInvalid":
                    errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                    return;

                case "startDateGreaterThanFinishDate":
                    errors.push(ErrorList.DATE_RANGE_INVALID);
                    return;

                case "surveyQuestionEmpty":
                    errors.push(ErrorList.EMPTY_QUESTION_ONE);
                    return;
                        
                default:
                    //NO ERROR TO CATCH
                    return;
                }
            });

            setInputErrors(errors);

            return Toast.error("Please make sure your entries are correct");
        }).finally(() => {
            setFormSubmitLoading(false);
        });
    };

    const togglePollVisibilityModal = () => {
        setShowPollVisibilityModal(!showPollVisibilityModal);
    };

    const handleSaveSelectedUsers = (
        allEmployeesSelected,
        allClientsSelected,
        selectedUsers,
        employeesCountSelected,
        clientsCountSelected) => {
        setAllEmployeesInSurvey(allEmployeesSelected);
        setEmployeesCountInPoll(employeesCountSelected);

        setAllClientsInSurvey(allClientsSelected);
        setClientsCountInPoll(clientsCountSelected);

        if (allEmployeesSelected && allClientsSelected) {
            updateSurvey("userGroup", EnumUserGroup.ALL_USERS);
            setSelectedUsers([]);
            setIsShowInitialUserGroupAddButton(false);
        }
        if (allEmployeesSelected && clientsCountSelected === 0) {
            updateSurvey("userGroup", EnumUserGroup.EMPLOYEES);
            setSelectedUsers([]);
            setIsShowInitialUserGroupAddButton(false);
        }
        if (allClientsSelected && employeesCountSelected === 0) {
            updateSurvey("userGroup", EnumUserGroup.CLIENTS);
            setSelectedUsers([]);
            setIsShowInitialUserGroupAddButton(false);
        }
        if (!allEmployeesSelected && !allClientsSelected) {
            if (selectedUsers.length > 0) {
                setSelectedUsers(selectedUsers);
                updateSurvey("userGroup", EnumUserGroup.SELECTED_USERS);
                setIsShowInitialUserGroupAddButton(false);
            } else {
                setSelectedUsers([]);
                setIsShowInitialUserGroupAddButton(true);
            }
        } 

        if ((allEmployeesSelected && clientsCountSelected !== 0) || (allClientsSelected && employeesCountSelected !== 0)) {
            setSelectedUsers(selectedUsers);
            updateSurvey("userGroup", EnumUserGroup.SELECTED_USERS);
            setIsShowInitialUserGroupAddButton(false);
        }
        setShowPollVisibilityModal(false);
    };

    if (formStep === FormStep.PAGE_CONGRATULATIONS) {
        return (
            <PageContainer>
                <PageCongratulations showSurveyForm={showSurveyForm} getSurveys={getSurveys} selectedSurveyOrPollStatusType={selectedSurveyOrPollStatusType} />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <PageHeadingLabel size="2.5rem">
                { isEditSurveyForm ? "Edit Survey" : "Create New Survey"}
            </PageHeadingLabel>
            <div className="row" style={{ maxWidth: "94.5rem" }}>
                <div className="description" style={{ marginBottom: "1em" }}>
                    Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability to <br/>
                    create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards <br/>
                    employees, clients, or both, ensuring you reach the right audience every time.
                </div>
            </div>

            <FormBackground>
                <Form width="inherit" onSubmit={(e) => { e.preventDefault(); }} style={{ padding: "2.5rem 0" }} >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                        <div style={{ width: "45%" }}>
                            <FormSectionHeader style={{ fontWeight: "600" }}>
                                Survey Information
                            </FormSectionHeader>
                            <SurveyNameField
                                survey={survey}
                                inputSurveyName={inputSurveyName}
                                inputErrors={inputErrors}
                                loading={isEditSurveyForm ? surveyInfoLoading : false}
                            />
                            <TypeOfSurveyField 
                                showSurveyForm={true}
                                survey={survey}
                                selectSurveyType={selectSurveyType}
                                inputErrors={inputErrors}
                                isEditSurveyForm={isEditSurveyForm}
                                loading={isEditSurveyForm ? surveyInfoLoading : false}
                            />
                            <SurveyDurationFields
                                showSurveyForm={showSurveyForm}
                                survey={survey}
                                inputStartDate={inputStartDate}
                                inputFinishDate={inputFinishDate}
                                inputErrors={inputErrors}
                                setInputTypeAs={setInputTypeAs}
                                isEditSurveyForm={isEditSurveyForm}
                                loading={isEditSurveyForm ? surveyInfoLoading : false}
                            />
                            <UserGroupField
                                showSurveyForm={true}
                                survey={survey}
                                selectUserGroup={selectUserGroup}
                                inputErrors={inputErrors}
                                isEditSurveyForm={isEditSurveyForm}
                                loading={isEditSurveyForm ? surveyInfoLoading : false}
                                togglePollVisibilityModal={togglePollVisibilityModal}
                                showPollVisibilityModal={showPollVisibilityModal}
                                handleSaveSelectedUsers={handleSaveSelectedUsers}
                                selectedUsers={selectedUsers}
                                clientsInPoll={allClientsInSurvey}
                                employeesInPoll={allEmployeesInSurvey}
                                employeesCountInPoll={employeesCountInPoll}
                                clientsCountInPoll={clientsCountInPoll}
                                isEditForm={isEditSurveyForm}
                                isShowInitialUserGroupAddButton={isShowInitialUserGroupAddButton}
                            />
                            <SurveyFrequencyField
                                showSurveyForm={true}
                                survey={survey}
                                inputErrors={inputErrors}
                                selectShowIn={selectShowIn}
                                selectShowFrequency={selectShowFrequency}
                                loading={isEditSurveyForm ? surveyInfoLoading : false}
                            />
                        </div>
                        <div style={{ width: "45%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div>
                                <FormSectionHeader style={{ fontWeight: "600" }}>
                                    Survey Questions
                                </FormSectionHeader>
                                <SurveyQuestionsFields
                                    survey={survey}
                                    inputQuestion={inputQuestion}
                                    activeQuestionCount={activeQuestionCount}
                                    addQuestion={addQuestion}
                                    removeQuestion={removeQuestion}
                                    inputErrors={inputErrors}
                                    isEditSurveyForm={isEditSurveyForm}
                                    loading={isEditSurveyForm ? surveyInfoLoading : false}
                                />
                            </div>
                            <div style={{ margin: "0 0 2.5rem 0" }}>
                                <SurveySubmissionAnonymousField
                                    setSurveyAnonymous={setSurveyAnonymous}
                                    surveyIsAnonymous={survey.isAnonymous}
                                    surveyStatus={survey.status}
                                    isEditSurveyForm={isEditSurveyForm}
                                    loading={isEditSurveyForm ? surveyInfoLoading : false}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "50px 3.75rem 22px 3.75rem" }}>
                        <hr/>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "0 3.75rem" }}>
                        {
                            formSubmitLoading ?
                                <LoadingIndicatorButton height="38px">
                                    <LoadingIndicatorIcon height="20px" width="20px" margin="5px"/>
                                </LoadingIndicatorButton>
                                :
                                <>
                                    <CancelButton borderRadius="7px" onClick={(e) => { showSurveyForm(false); }}>Cancel</CancelButton>
                                    <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => { isEditSurveyForm ? updateFormSubmission(surveyToEdit) : createFormSubmission(); }}>Save</SaveButton>
                                </>
                        }
                    </div>
                </Form>
            </FormBackground>
        </PageContainer>
    );
};

const SurveyNameField = (props) => {
    const { survey, inputSurveyName, inputErrors, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

    return (
        <>
            <Text size="16px" align="left" weight="bold">
                Enter Survey Name
            </Text>
            { loading ?
                <Skeleton height="50px" width="100%" animation="wave" />
                :
                <FormField borderRadius="12px" size="16px" padding="10px 20px" height="50px"
                    placeholderColor="#808080" border="1px solid #E9EBEF"
                    placeholderAlignment="left"
                    width="100%"
                    type="text"
                    style={{ textAlign: "left", margin: "0" }}
                    placeholder="Enter name here"
                    onChange={(event) => { inputSurveyName(event); }}
                    errors={surveyNameError}
                    maxLength="100"
                    value={survey.name}
                    showErrors={surveyNameError.length > 0}
                />
            }
        </>
    );
};

const TypeOfSurveyField = (props) => {
    const { survey, selectSurveyType, inputErrors, isEditSurveyForm, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const surveyTypeError = getErrorStrings(ErrorList.EMPTY_SURVEY_TYPE);

    return (
        <>
            <Text align="left" size="16px" weight="500">
                Select The Survey Type
            </Text>
            { loading ?
                <Skeleton height="50px" width="100%" animation="wave" />
                :
                <Select
                    isSearchable={false}
                    isDisabled={isEditSurveyForm}
                    value={surveyTypeOptions.find(obj => obj.value == survey.surveyType)}
                    className="surveyTypeDropdown"
                    placeholder="Please select..."
                    options={ surveyTypeOptions }
                    onChange={(choice) => selectSurveyType(choice)}
                    styles={isEditSurveyForm ? customDisabledStyles : customStyles}
                    components={{ Option: IconOption, SingleValue: SingleValue }}
                />
            }

            { surveyTypeError.length > 0 &&
                <ErrorText errorMargin="15px 20px 15px">
                    {surveyTypeError}
                </ErrorText>
            }
        </>
    );
};

const SurveyDurationFields = (props) => {
    const { survey, inputStartDate, inputFinishDate, inputErrors, isEditSurveyForm, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

    const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
    const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);

    const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
    const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

    const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
    const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

    const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

    const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyFinishDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);

    return (
        <>
            <FlexContainer align="center" style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", gap: "1.5rem" }}>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1 45%" }}>
                    <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                        Start Date
                    </Text>
                    {loading ?
                        <Skeleton height="50px" width="100%" animation="wave" />
                        :
                        <ValidatedDateInput
                            placeholder="Select date"
                            onChange={(event) => {inputStartDate(event);} }
                            errors={surveyStartDateError}
                            value={survey.startDate}
                            disabled={isEditSurveyForm && survey.status !== "Scheduled"}
                            showErrors={surveyStartDateError.length > 0}
                        />
                    }
                </div>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1 45%" }}>
                    <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                        Finish Date
                    </Text>
                    {loading ?
                        <Skeleton height="50px" width="100%" animation="wave" />
                        :
                        <ValidatedDateInput
                            placeholder="Select date"
                            onChange={(event) => {inputFinishDate(event);} }
                            errors={surveyFinishDateError}
                            value={survey.finishDate}
                            disabled={false}
                            showErrors={surveyFinishDateError.length > 0}
                        />
                    }
                </div>
            </FlexContainer>
            
            { hasAnySurveyDurationErrors &&
                <ErrorText errorMargin="0px">
                    {startDateInvalid.length > 0 ? startDateInvalid : null}
                    {finishDateInvalid.length > 0 ? finishDateInvalid : null}
                    {dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null}
                    {dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null}
                    {dateRangeInvalid.length > 0 ? dateRangeInvalid : null}
                </ErrorText>
            }
        </>
    );
};

const UserGroupField = (props) => {
    const {
        inputErrors,
        isEditSurveyForm,
        survey,
        loading,
        togglePollVisibilityModal,
        showPollVisibilityModal,
        handleSaveSelectedUsers,
        selectedUsers,
        clientsInPoll,
        employeesInPoll,
        employeesCountInPoll,
        clientsCountInPoll,
        isEditForm,
        isShowInitialUserGroupAddButton
    } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const userGroupError = getErrorStrings(ErrorList.EMPTY_USER_GROUP);
    const selectedUsersError = getErrorStrings(ErrorList.EMPTY_SELECTED_USERS);

    const SelectedPeopleIcon = styled.div`
        width: 30px;
        height: 30px;
        background: #D6D6D6 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #FFFFFF;
        opacity: 1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
    `;

    const SelectedPeopleItem = styled.div`
        width: 247px;
        height: 49px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 5px #0000001A;
        border: 1px solid #E0E0E0;
        border-radius: 15px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        padding-left: 14px;
        margin-bottom: 9px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
    `;

    const renderSelectedPeopleItem = (label, count, onClick, isAll) => (
        <SelectedPeopleItem onClick={onClick}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <SelectedPeopleIcon>
                    <img src={UserGroup} alt="peopleIcon" height="14px" style={{ margin: "auto" }} />
                </SelectedPeopleIcon>
                <Text size="16px" weight="500" style={{ marginLeft: "0.5rem" }}>
                    {isAll ? `All ${label}` : `${count} ${count === 1 ? label.slice(0, -1) : label}`}
                </Text>
            </div>
            <Text size="16px" color="#006CFF" weight="400" style={{ marginRight: "1rem" }}>
                Edit
            </Text>
        </SelectedPeopleItem>
    );

    const renderSelectedPeopleSection = (
        label,
        peopleInPoll,
        countInPoll,
        isEditSurveyForm,
        surveyCount,
        totalSurveyCount,
        toggleModal
    ) => {
        if (peopleInPoll || countInPoll > 0) {
            return renderSelectedPeopleItem(label, countInPoll, toggleModal, peopleInPoll);
        } else if (isEditSurveyForm && surveyCount > 0) {
            const isTotalMatch = totalSurveyCount === surveyCount;
            return renderSelectedPeopleItem(label, surveyCount, toggleModal, isTotalMatch);
        }
        return null;
    };

    return (
        <>
            <Text align="left" size="16px" weight="bold" margin="16px 0px 0px 0px">
                Select User Group or Specific Users
            </Text>
            <Text align="left" size="14px" weight="300" color="#8291B2" margin="0">
                Select to send this survey to your Employees, Clients to select users.
            </Text>

            { loading ?
                <Skeleton height="50px" width="100%" animation="wave" />
                :
                <>
                    <div style={{ marginBlockStart: "1em", marginBlockEnd: "1em", display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <PollVisibilitySection
                            togglePollVisibilityModal={togglePollVisibilityModal}
                            showPollVisibilityModal={showPollVisibilityModal}
                            handleSave={handleSaveSelectedUsers}
                            pollRespondentsUuids={isEditForm ? survey.usersSelectedUuid : selectedUsers}
                            clientsInPoll={isEditForm ? (survey.totalClientCount === survey.clientCount) : clientsInPoll}
                            employeesInPoll={isEditForm ? (survey.totalEmployeeCount === survey.employeeCount) : employeesInPoll}
                            employeesCountInPoll={isEditForm ? survey.employeeCount : employeesCountInPoll}
                            clientsCountInPoll={isEditForm ? survey.clientCount : clientsCountInPoll}
                            isEditForm={isEditForm}
                            modalTitle="Choose Specific Survey Participants"
                            pollVisibilityError={selectedUsersError}
                            label="Select Users"
                            buttonWidth="17.5rem"
                            buttonHeight="3.125rem"
                            showButton={isShowInitialUserGroupAddButton}
                        />
                        <FlexContainer direction="row" gap="1rem">
                            {renderSelectedPeopleSection(
                                "Employees",
                                employeesInPoll,
                                employeesCountInPoll,
                                isEditSurveyForm,
                                survey.employeeCount,
                                survey.totalEmployeeCount,
                                togglePollVisibilityModal
                            )}
                            {renderSelectedPeopleSection(
                                "Clients",
                                clientsInPoll,
                                clientsCountInPoll,
                                isEditSurveyForm,
                                survey.clientCount,
                                survey.totalClientCount,
                                togglePollVisibilityModal
                            )}
                        </FlexContainer>
                    </div>
                </>
            }

            { userGroupError.length > 0 &&
                <ErrorText errorMargin="15px 20px 15px">
                    {userGroupError.length > 0 ? userGroupError : null}
                </ErrorText>
            }
        </>
    );
};

const SurveyQuestionsFields = (props) => {
    const { inputQuestion, inputErrors, survey, activeQuestionCount, addQuestion, removeQuestion, isEditSurveyForm, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

    let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
    let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
    let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

    const createSurveyQuestion = () => {
        return (
            <div style={{ width: "90%" }}>
                {activeQuestionCount >= 1 && <QuestionContainer questionNumber={1} questionValue={survey.surveyQuestions.one.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} showRemoveQuestion={false}></QuestionContainer>}
                {activeQuestionCount === 1 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}

                {activeQuestionCount >= 2 && 
                    <>
                        <div style={{ display: "flex", direction: "row", position: "relative" }}>
                            <div style={{ display: "block", width: "100%" }}>
                                <QuestionContainer questionNumber={2} questionValue={survey.surveyQuestions.two.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} removeQuestion={removeQuestion} questionElement={"two"} showRemoveQuestion={activeQuestionCount === 2}></QuestionContainer>
                            </div>
                        </div>
                        {activeQuestionCount === 2 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}
                    </>
                }
                {activeQuestionCount >= 3 && 
                    <>
                        <div style={{ display: "flex", direction: "row", position: "relative" }}>
                            <div style={{ display: "block", width: "100%" }}>
                                <QuestionContainer questionNumber={3} questionValue={survey.surveyQuestions.three.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} removeQuestion={removeQuestion} questionElement={"three"} showRemoveQuestion={activeQuestionCount === 3}></QuestionContainer>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    };

    const editSurveyQuestion = () => {
        return (
            <div style={{ width: "90%" }}>
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={1} questionValue={survey.surveyQuestions.one ? survey.surveyQuestions.one.question : ""} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} />
                        </div>
                    </div>
                }
           
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={2} questionValue={survey.surveyQuestions.two ? survey.surveyQuestions.two.question : ""} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} />
                        </div>
                    </div>
                }
          
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={3} questionValue={survey.surveyQuestions.three ? survey.surveyQuestions.three.question : ""} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} />
                        </div>
                    </div>
                }
            </div>
        );
    };

    return (
        loading ?
            <div style={{ paddingTop: "1rem" }}>
                <Skeleton animation="wave" width="auto" height="50px" />
            </div>
            : isEditSurveyForm ? editSurveyQuestion() : createSurveyQuestion()
    );
};

const SurveyFrequencyField = (props) => {
    const { inputErrors, selectShowIn, selectShowFrequency, survey, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
    const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

    return (
        <>
            <Text align="left" size="16px" weight="500">
                Show Survey In:
            </Text>
            <FlexContainer>
                { loading ?
                    <Skeleton height="50px" width="100%" animation="wave" />
                    :
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyShowInOptions[0]}
                        value={ surveyShowInOptions.find(obj => obj.value == survey.showIn) }
                        options={ surveyShowInOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                        components={{ Option: IconOption, SingleValue: SingleValue }}
                    />
                }

                <ErrorText>
                    {showInError}
                </ErrorText>


                <Text align="left" margin="10px 0px" size="16px" weight="500">
                    Select Survey Frequency?
                </Text>

                { loading ?
                    <Skeleton height="50px" width="100%" animation="wave" />
                    :
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyFrequencyOptions[0]}
                        options={ surveyFrequencyOptions }
                        onChange={(choice) => selectShowFrequency(choice)}
                        value={ surveyFrequencyOptions.find(obj => obj.value == survey.showFrequency) }
                        styles={customStyles}
                        components={{ Option: IconOption, SingleValue: SingleValue }}
                    />
                }
                <ErrorText>
                    {showFrequencyError}
                </ErrorText>
            </FlexContainer>
        </>
    );
};

const SurveySubmissionAnonymousField = (props) => {
    const { setSurveyAnonymous, surveyIsAnonymous, surveyStatus, isEditSurveyForm, loading } = props;

    return (
        <>
            <Text align="left" size="16px" weight="500">
                Make Survey Submission Anonymous (Optional)
            </Text>
            <Text align="left" size="14px" color="#8291B2" maxWidth="540px" wordBreak="break-word">
            By checking this box, all survey responses will be collected anonymously,
            allowing users to share their thoughts freely without any personal identifiers.
            Once the survey becomes active, this setting cannot be changed,
            so make sure to decide before proceeding to promote candid and unbiased feedback.
            </Text>
            { loading ?
                <Skeleton animation="wave" width="32px" height="32px" />
                :
                <CheckboxContainer>
                    <label>
                        { surveyStatus !== SurveyStatusType.SCHEDULED && isEditSurveyForm &&
                                <DisabledStyledCheckbox
                                    border="1px solid #8291B2"
                                    color={surveyIsAnonymous === "1" ? "#8291B2" : "#36be24" }
                                    height="32px"
                                    width="32px"
                                    padding="7px"
                                    radius="10px"
                                    value={true}
                                    checked={surveyIsAnonymous === "1"}
                                />
                        }
                        {((surveyStatus === SurveyStatusType.SCHEDULED && isEditSurveyForm) || !isEditSurveyForm) &&
                            <Checkbox
                                color="#36be24"
                                height="32px"
                                width="32px"
                                padding="7px"
                                radius="10px"
                                value={true}
                                checked={surveyIsAnonymous}
                                onChange={ (e) => setSurveyAnonymous(e.target.checked) }
                            />
                        }
                    </label>
                </CheckboxContainer>
            }
        </>
    );
};

const PageCongratulations = (props) => {
    const hideFormAndReloadList = () => {
        const { showSurveyForm, getSurveys, selectedSurveyOrPollStatusType } = props;

        showSurveyForm(false);
        getSurveys(selectedSurveyOrPollStatusType);
    };

    return (
        <>
            <PageHeadingLabel size="2.5rem">Create New Survey</PageHeadingLabel>
            <div className="row" style={{ maxWidth: "94.5rem" }}>
                <div className="description" style={{ marginBottom: "1em" }}>
                    Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability to <br/>
                    create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards <br/>
                    employees, clients, or both, ensuring you reach the right audience every time.
                </div>
            </div>

            <FormBackground style={{ margin: "0 0 3rem 0",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                maxHeight: "60rem",
                flexGrow: "1",
            }}>
                <div style={{ margin: "auto" }}>
                    <Text color="#000000" size="40px" style={{ marginBlockEnd: "0px" }}>
                        Congratulations!
                    </Text>
                    <Text color="#000000" size="40px" style={{ marginBlockStart: "0px" }}>
                        Your Survey Has Been Created.
                    </Text>
                    <Text color="black" size="16px" width="650px" lineHeight="25px" margin="0">
                        You can always make edits to your scheduled and active surveys via the <br/>
                        &rsquo;Surveys&rsquo; main screen and view insights once your survey has gone live.
                    </Text>

                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "row" }}>
                        <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "100px", height: "40px", marginTop: "65px" }}
                            onClick={() => { hideFormAndReloadList(); }} >
                            Close
                        </StyledButton>
                    </div>
                </div>
            </FormBackground>
        </>
    );
};

export default SurveyForm;