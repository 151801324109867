import React, { Component, forwardRef } from "react";
import styled from "styled-components";
import Text from "src/components/Text";
import CalendarIcon from "src/img/survey/calendar.png";
import DatePicker from "react-datepicker";
import moment from "moment";

export const ErrorText = styled(Text)`
    color: red;
    text-align: ${p => (p.align || p.errorTextAlign) || "left"};
    font-size: 12px;
    margin: ${p => p.errorMargin || "0 20px 5px"};
    height: 12px;
    height: ${p => (p.errorTextHeight) || "12px"};
    white-space: pre-wrap;
`;

const CustomDatePicker = styled.div`
    font-size: 0.875rem;
    height: 3.125rem;
    placeholder-color: #000000;
    border: ${p => p.isError ? "1px solid red" : "1px solid #E9EBEF"};
    border-radius: 12px;
    background-color: ${p => p.backgroundColor || "#fff"};
    font-weight: 500;
    textAlign: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: ${p => p.cursor || "pointer"};
    user-select: none;
    width: 100%;
    @media (max-width: 600px) {
        font-size: 0.875rem;
    }
`;

class ValidatedDateInput extends Component {
    render() {
        const { errors, placeholder, onChange, showErrors, errorTextAlign, value, disabled, minDate, ...styleProps } = this.props;
        const errorsArray = errors ? errors : [];

        const CustomInput = forwardRef(
            ({ value, onClick, styleProps }, ref) => (
                <CustomDatePicker
                    style={styleProps}
                    onClick={onClick}
                    ref={ref}
                    backgroundColor={disabled ? "#F0F0F0" : ""}
                    cursor={disabled ? "not-allowed" : ""}
                    isError={showErrors}
                >
                    <span style={{ margin: "auto auto auto 1rem", flex: "1", textAlign: "left", color: value === "" ? "#808080" : "" }}>
                        {value === "" ? placeholder : moment(value).format("Do MMMM, YYYY")}
                    </span>
                    <i style={{
                        backgroundColor: "inherit",
                        width: "10%",
                        padding: "auto 16px auto auto",
                        borderLeft: "none !important",
                        borderRadius: "0px 10px 10px 0px",
                        display: "inline",
                        margin: "auto 16px auto auto"
                    }}>
                        <img src={CalendarIcon} alt={CalendarIcon} height="24px" width="24px" style={{ verticalAlign: "middle" }}/>
                    </i>
                </CustomDatePicker>
            ),
        );

        return (
            <>
                <DatePicker
                    selected={value}
                    max="9999-12-31"
                    minDate={minDate}
                    onChange={(date) => {onChange(date);}}
                    disabled={disabled}
                    placeholder={placeholder}
                    customInput={<CustomInput showErrors={showErrors}/>
                    }
                />
                { showErrors && <ErrorText errorTextAlign={errorTextAlign} {...styleProps}>{errorsArray}</ErrorText> }
            </>
        );
    }
}

export default ValidatedDateInput;