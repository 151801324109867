import React from "react";
import styled from "styled-components";

const ContainerOverflow = styled.div`
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 1em;

    ::-webkit-scrollbar {
        display: block;
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #174A84;
        border-radius: 6px;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #D1DBE6;
        border-radius: 6px;
        margin-right: 30px;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #D1DBE6;
        border-radius: 6px;
    }
`;

const TablePreview = styled.div`
    .thead * {
        font-weight: 700;
    }
    .tbody * {
        font-weight: 400;
    }
    .tbody, .thead{
        display: table;
        width: 100%;
    }

    .tbody > *, .thead > * {
        display: table-row;
    }

    .tbody .col, .thead .col {
        display: table-cell;
        padding: 12px 16px 12px 16px;
        width: calc(100% / 4); /* Assuming 3 columns */
    }

    .col-fullname-width {
        width: calc(100% / 2) !important;
    }

    .tbody .col {
        border-left: 1px solid #8291B2;
        border-top: 1px solid #8291B2;
    }

    .tbody .lastcol {
        border-right: 1px solid #8291B2;
    }

    .tbody .lastrow > .col {
        border-bottom: 1px solid #8291B2;
    }
`;

const CircledInitials = styled.div`
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    width: 42px;
    height: 42px;
    background-color: #D6D6D6;
    color: #6F6F6F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
`;

const UserRow = ({ index, maxIndex, uuid, nameInitials, fullName, department, jobTitle, isReaderTypeEmployee }) => {
    const isFirstRow = index === 0;
    const isLastRow = index === maxIndex;
    const isOnlyOneRow = isFirstRow && isLastRow;

    const topLeftRadius = "8px 0px 0px 0px";
    const topRightRadius = "0px 8px 0px 0px";
    const bottomLeftRadius = "0px 0px 0px 8px";
    const bottomRightRadius = "0px 0px 8px 0px";

    let divStyleFirstColumn = isFirstRow ? { borderRadius: topLeftRadius } : isLastRow ? { borderRadius: bottomLeftRadius } : {};
    let divStyleLastColumn = isFirstRow ? { borderRadius: topRightRadius } : isLastRow ? { borderRadius: bottomRightRadius } : {};

    if (isOnlyOneRow) {
        divStyleFirstColumn = { borderRadius: "8px 0px 0px 8px" };
        divStyleLastColumn = { borderRadius: "0px 8px 8px 0px" };
    }

    return (
        <div className={`row ${isLastRow ? "lastrow" : ""}`} key={uuid + "_" + index}>
            <div className="col col-fullname-width" style={ divStyleFirstColumn }>
                <div style={{ display: "inline-block" }}><CircledInitials>{nameInitials}</CircledInitials></div> {fullName}
            </div>
            <div
                className={`col ${!isReaderTypeEmployee ? "lastcol" : ""}`}
                style={ !isReaderTypeEmployee ? divStyleLastColumn : null }
            >
                {department}
            </div>
            {isReaderTypeEmployee && <div className={"col lastcol"} style={ divStyleLastColumn }>{jobTitle}</div>}
        </div>
    );
};

const ViewersListPreview = ({ viewersList, isReaderTypeEmployee, modalType }) => {
    if (!viewersList || viewersList.length === 0) {
        return `No user ${modalType} found`;
    }

    return (
        <TablePreview>
            <div className="thead">
                <div className="row" key="theadrow">
                    <div className="col col-fullname-width">{isReaderTypeEmployee ? "Employee" : "Client"} Name</div>
                    <div className="col">{isReaderTypeEmployee ? "Department / Group" : "Client Type"}</div>
                    {isReaderTypeEmployee && <div className="col">Job Title</div>}
                </div>
            </div>
            <ContainerOverflow>
                <div className="tbody">
                    {viewersList.map((viewer, index) => {
                        const nameInitials = viewer.firstName.charAt(0) + viewer.lastName.charAt(0);
                        const fullName = `${viewer.firstName} ${viewer.lastName}`;
                        return (
                            <UserRow
                                key={viewer.uuid}
                                index={index}
                                maxIndex={viewersList.length - 1}
                                uuid={viewer.uuid}
                                nameInitials={nameInitials}
                                fullName={fullName}
                                department={isReaderTypeEmployee ? viewer.department : viewer.employmentPosition}
                                jobTitle={viewer.employmentPosition}
                                isReaderTypeEmployee={isReaderTypeEmployee}
                            />
                        );
                    })}
                </div>
            </ContainerOverflow>
        </TablePreview>
    );
};

export default ViewersListPreview;
