import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import { API_URL } from "src/scenes/App";
import Initial from "./forms/Initial";
import UploadLogo from "./forms/UploadLogo";
import UploadIcon from "./forms/UploadIcon";
import StartScreen from "./forms/StartScreen";
import SelectColor from "./forms/SelectColor";
import Email from "./forms/Email";
import Finish from "./forms/Finish";
import { CustomiseContext } from "./CustomiseContext";
import { UserContext } from "src/scenes/App/UserContext";

import ImgMeAppGetTheApp from "src/img/ImgMeAppGetTheApp.jpg";

import {
    LoadingIndicator, Toast, FlexContainer, PageContainer,
    ExplainParagraphLabel, PageHeadingLabel
} from "src/components";

import { UserKeys } from "src/constants/userDetails";
import { appFeatureLevels } from "src/constants/appFeatureLevels";
import { updateFeatureLevel } from "src/utils/appFeatures";

export const Forms = {
    INITIAL: 0,
    UPLOAD: 1,
    COLOR: 2,
    ICON: 3,
    START_SCREEN: 4,
    FINISH: 5,
    EMAIL: 6,
};

export const Screens = {
    SPLASH: 0,
    MAIN: 1, //Quote
    ARTICLES: 2,
    BODY_AND_MIND: 3,
    NOTICEBOARD: 4,
    GOALS: 5,
    PLAYLISTS: 6,
    SURVEY: 7,
    PHONE: 8
};

//default values inside json files need to be updated
export const AnimationColors = {
    BACKGROUND: "#90B5FA",
    PRIMARY: "#2C349B",
    SECONDARY: "#346E73"
};

const RightColumnPhoto = styled.div`
    flex: 50%;
    border-radius: 0px 12px 12px 0px;
    overflow: hidden;
`;

const RightColumnPhotoCover = styled.div`
    background-image: url(${ImgMeAppGetTheApp});
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`;

class Customise extends Component {
    static contextType = UserContext;
    state = {
        loading: false,
        currentForm: Forms.INITIAL,
        isNextClickable: true,
        logoFile: [],
        iconFile: [],
        iconStatus: undefined,
        assetLogoUrl: "",
        tempLogoUrl: "",
        assetIconUrl: "",
        backGroundColor: AnimationColors.BACKGROUND,
        currentScreen: Screens.MAIN,
        primaryColor: AnimationColors.PRIMARY,
        secondaryColor: AnimationColors.SECONDARY,
        errorMessage: "",
        companyName: this.context[UserKeys.COMPANY_NAME],
        employeeStartScreen: "",
        clientStartScreen: "",
        isFinishing: undefined
    };

    componentDidMount() {
        if (this.context[UserKeys.IS_CUSTOMIZE_COMPLETE]) {
            this.setState({
                isNextClickable: true,
                currentForm: Forms.FINISH,
                loading: true
            });

            axios.get(`${API_URL}/company/settings`, {
                headers: {
                    Authorization: "Bearer " + this.context.token
                }
            }).then(res => {
                const {
                    logoUrl,
                    iconUrl,
                    iconStatus,
                    backgroundColor,
                    primaryColor,
                    secondaryColor,
                    companyName,
                    employeeStartScreen,
                    clientStartScreen
                } = res.data;

                this.setState({
                    assetLogoUrl: logoUrl,
                    assetIconUrl: iconUrl,
                    iconStatus: iconStatus,
                    isNextClickable: true,
                    currentForm: Forms.FINISH,
                    backGroundColor: backgroundColor,
                    primaryColor: primaryColor,
                    secondaryColor: secondaryColor,
                    companyName: companyName,
                    employeeStartScreen: employeeStartScreen,
                    clientStartScreen: clientStartScreen,
                    loading: false
                });
            }).catch(error => {
                return Toast.error(error.message);
            });
        }
    }

    hexToRgbaPercent = (hex) => {
        //  Only do the conversion if a colour has been selected (is a hex value)
        if (typeof hex !== "string" || hex.charAt(0) !== "#" || (hex.length !== 4 && hex.length < 7)) {
            return hex;
        }

        hex = hex.replace("#", "");
        let r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
        let g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
        let b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
        return [ r / 255, g / 255, b / 255, 1];
    };

    nextLogoUploading = () => {
        this.setState({
            assetLogoUrl: "",
            isNextClickable: false,
            tempLogoUrl: ""
        });
    };

    nextLogoAvailable = (tempLogoUrl) => {
        this.setState({
            isNextClickable: true,
            tempLogoUrl: tempLogoUrl
        });
    };

    nextIconAvailable = (assetIconUrl) => {
        this.setState({
            isNextClickable: true,
            assetIconUrl: assetIconUrl
        });
    };

    leftScreen = () => {
        const { currentScreen, currentForm } = this.state;
        const screenIds = this.getSlidesForScreen(currentForm);
        const lastScreenIndex = screenIds.length - 1;

        if (currentScreen <= 0) {
            this.setState({
                currentScreen: lastScreenIndex,
            });
        } else {
            // Add -1 to the index
            this.setState({
                currentScreen: currentScreen - 1,
            });
        }
    };

    rightScreen = () => {
        const { currentScreen, currentForm } = this.state;
        const screenIds = this.getSlidesForScreen(currentForm);
        const lastScreenIndex = screenIds.length - 1;

        // Move to the next screen until reaching the end
        if (currentScreen < lastScreenIndex) {
            // Add +1 to the index
            this.setState({
                currentScreen: currentScreen + 1,
            });
        } else {
            this.setState({
                currentScreen: 0,
            });            
        }
    };

    nextClick = () => {
        const {
            logoFile,
            tempLogoUrl,
            assetLogoUrl,
            assetIconUrl,
            backGroundColor,
            primaryColor,
            secondaryColor,
            employeeStartScreen,
            clientStartScreen
        } = this.state;

        if (!this.state.isNextClickable) {
            return;
        }

        if (this.state.currentForm === Forms.INITIAL) {
            this.setState({
                currentForm: Forms.UPLOAD,
                isNextClickable: tempLogoUrl !== "",
            });
        } else if (this.state.currentForm === Forms.UPLOAD) {
            this.setState({
                currentScreen: Screens.MAIN,
                currentForm: Forms.COLOR
            });
        } else if (this.state.currentForm === Forms.COLOR) {
            this.setState({ currentForm: Forms.ICON });
        } else if (this.state.currentForm === Forms.ICON) {
            this.setState({ currentForm: Forms.START_SCREEN });
        } else if (this.state.currentForm === Forms.START_SCREEN) {
            this.setState({ isFinishing: true });

            const isCustomizeComplete = this.context[UserKeys.IS_CUSTOMIZE_COMPLETE];
            const logoUrl = tempLogoUrl === ""
                ? assetLogoUrl
                : logoFile[0].fullUrl.split("?")[0];
            const payload = {
                logoUrl,
                iconUrl: assetIconUrl,
                backgroundColor: backGroundColor,
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
                employeeStartScreen: employeeStartScreen,
                clientStartScreen: clientStartScreen
            };

            const request = isCustomizeComplete
                ? axios.put(`${API_URL}/company/settings`, payload, {
                    headers: { Authorization: `Bearer ${this.context.token}` },
                })
                : axios.post(`${API_URL}/company/settings`, payload, {
                    headers: { Authorization: `Bearer ${this.context.token}` },
                });

            request
                .then((res) => {
                    if (!isCustomizeComplete) {
                        this.context.updateUserContextProperties({
                            [UserKeys.PERMISSIONS]: JSON.parse(res.data.permissions),
                        });
                        this.context.updateUserContextProperties({
                            [UserKeys.IS_CUSTOMIZE_COMPLETE]: true,
                        });
                        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        const featureLevel = appFeatureLevels.FEATURE_LEVEL_3; // used to set what feature level we're currently at
                        updateFeatureLevel(featureLevel, timeZone, this.context.token);
                    }

                    if (tempLogoUrl !== "") {
                        this.uploadSignedUrl();
                    }

                    this.setState({
                        currentForm: Forms.FINISH,
                        isFinishing: false,
                        currentScreen: Screens.MAIN,
                        iconStatus: res.data.iconStatus,
                        backGroundColor: backGroundColor,
                        primaryColor: primaryColor,
                        secondaryColor: secondaryColor,
                        employeeStartScreen: employeeStartScreen,
                        clientStartScreen: clientStartScreen
                    });
                })
                .catch((error) => {
                    Toast.error(error.message);
                });
        } else if (this.state.currentForm === Forms.EMAIL) {
            this.setState({ currentForm: Forms.FINISH });
        }
    };

    uploadSignedUrl() {
        const { logoFile } = this.state;
        //set parameters applied in S3 updating
        const parameter = {
            headers: {
                "Content-Type": logoFile[0].fileToUpload.type
            },
            //set current updating percentage to S3 of the file and used to display on screen
            onUploadProgress: progressEvent => {
                let uploadPercent = (progressEvent.loaded / progressEvent.total) * 100;
                //get current time and add it to the end of logo url in order to guarantee the file can be
                //auto refreshed when a new logo is updated due to the updated logo's url is the same
                let currentTime = new Date().getTime();

                this.setState({
                    isUploading: true,
                    timestamp: currentTime,
                    uploadPercentage: uploadPercent,
                });
            }
        };

        //send updated file to the pre-signed url
        axios.put(logoFile[0].fullUrl, logoFile[0].fileToUpload, parameter)
            .then(result => {
                const logoUrlToSave = logoFile[0].fullUrl.substring(0, logoFile[0].fullUrl.indexOf("?"));
                this.context.updateUserContextProperties({
                    [UserKeys.COMPANY_LOGO_URL]: logoUrlToSave
                });
                this.setState({
                    logoFile: [], //reset to blank
                    assetLogoUrl: logoUrlToSave
                });
            })
            .catch(err => {
                return Toast.error(err.message);
            });
    }

    backToCustomise = () => {
        this.setState({
            currentScreen: Screens.MAIN,
            currentForm: this.context.isCustomizeComplete ? Forms.FINISH : Forms.INITIAL,
            isNextClickable: true,
        });
    };

    goToStep1 = () => {
        this.setState({
            currentForm: Forms.UPLOAD, 
            currentScreen: Screens.MAIN,
        });
    };

    previousClick = (currentForm, currentScreen) => {
        this.setState({
            currentForm: currentForm, 
            currentScreen: currentScreen,
        });
    };

    handleChange = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    setColor = (color) => {
        //detect if this color comes from color picker,  or converted hex color result when loading
        if (color.rgb) {
            return [color.rgb.r / 255, color.rgb.g / 255, color.rgb.b / 255, 1];
        } else {
            return color;
        }
    };

    updateBackgroundColor = (color) => {
        if (color.hex !== this.state.primaryColor && color.hex !== this.state.secondaryColor) {
            this.setState({
                backGroundColor: color.hex
            });
        }
    };

    updatePrimaryColor = (color) => {
        if (color.hex !== this.state.backGroundColor && color.hex !== this.state.secondaryColor) {
            this.setState({
                primaryColor: color.hex
            });
        }
    };

    updateSecondaryColor = (color) => {
        if (color.hex !== this.state.backGroundColor && color.hex !== this.state.primaryColor) {
            this.setState({
                secondaryColor: color.hex
            });
        }
    };

    /**
     * @param {int} currentForm
     * @return {int[]}
     */
    getSlidesForScreen(currentForm) {
        switch (currentForm) {
        //case Forms.UPLOAD:
        //    return [ Screens.SPLASH, Screens.MAIN ];
        default:
            return [ Screens.SPLASH, Screens.MAIN, Screens.ARTICLES, Screens.BODY_AND_MIND, Screens.NOTICEBOARD, Screens.GOALS, Screens.PLAYLISTS, Screens.SURVEY];
        }
    }

    /**
     * Reads colours from state. If any one (or more) is still in hex format #RRGGBB it will be
     * converted to an RGBA arrays of floats.
     * @returns {{backgroundColor: float[], primaryColor: float[], secondaryColor: float[]}}
     */
    buildColourPalette = () => {
        let { primaryColor, secondaryColor, backGroundColor } = this.state;
        primaryColor = this.hexToRgbaPercent(primaryColor);
        secondaryColor = this.hexToRgbaPercent(secondaryColor);
        backGroundColor = this.hexToRgbaPercent(backGroundColor);
        return {
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            backgroundColor: backGroundColor
        };
    };

    render() {
        const {
            loading,
            currentForm,
            logoFile,
            iconFile,
            currentScreen,
            isNextClickable,
            backGroundColor,
            primaryColor,
            secondaryColor,
            assetLogoUrl,
            tempLogoUrl,
            assetIconUrl,
            iconStatus,
            companyName,
            employeeStartScreen,
            clientStartScreen,
            isFinishing
        } = this.state;

        const screenIds = this.getSlidesForScreen(currentForm);

        if (loading) {
            return <LoadingIndicator />;
        }

        return (
            <PageContainer>
                <PageHeadingLabel>
                    My Me App
                </PageHeadingLabel>

                <div style={{ marginBottom: "1.5rem" }}>
                    <ExplainParagraphLabel style={{ fontSize: "0.8750em", width: "50vw" }}>
                        To get started, simply upload your company&rsquo;s logo, select three branding colours and upload your <br />
                        custom app icon. This will help differentiate your business and increase brand awareness with an <br />
                        innovative approach to promoting positivity and wellbeing.
                    </ExplainParagraphLabel>
                </div>

                <CustomiseContext.Provider value={{
                    nextLogoAvailable: this.nextLogoAvailable,
                    nextLogoUploading: this.nextLogoUploading,
                    nextIconAvailable: this.nextIconAvailable,
                    nextClick: this.nextClick,
                    logoFile: logoFile,
                    iconFile: iconFile,
                    assetLogoUrl: assetLogoUrl,
                    tempLogoUrl: tempLogoUrl,
                    assetIconUrl: assetIconUrl, 
                    iconStatus: iconStatus,
                    currentForm: currentForm,
                    screenIds: screenIds,
                    token: this.context.token,
                    leftScreen: this.leftScreen,
                    rightScreen: this.rightScreen,
                    currentScreen: currentScreen,
                    uploadFile: this.handleChange,
                    isNextClickable: isNextClickable,
                    backToCustomise: this.backToCustomise,
                    previousClick: this.previousClick,
                    backGroundColor: backGroundColor,
                    primaryColor: primaryColor,
                    secondaryColor: secondaryColor,
                    colorChange: this.handleChange,
                    goToStep1: this.goToStep1,
                    companyName: companyName,
                    colourPalette: this.buildColourPalette(),
                    updateBackgroundColor: this.updateBackgroundColor,
                    updatePrimaryColor: this.updatePrimaryColor,
                    updateSecondaryColor: this.updateSecondaryColor,
                    updateUserStartScreen: this.handleChange,
                    employeeStartScreen: employeeStartScreen,
                    clientStartScreen: clientStartScreen,
                    isComplete: this.context[UserKeys.IS_CUSTOMIZE_COMPLETE],
                    email: this.context.email,
                    isFinishing: isFinishing
                }}>
                    { currentForm === Forms.EMAIL &&
                        <FlexContainer style={{
                            maxWidth: "1400px",
                            backgroundColor: "#FFF",
                            borderRadius: "12px",
                            display: "flex",
                            flexDirection: "unset"
                        }}>
                            <div style={{ padding: "40px", flex: "50%" }}>
                                <Email />
                            </div>

                            <RightColumnPhoto>
                                <RightColumnPhotoCover />
                            </RightColumnPhoto>
                        </FlexContainer>
                    }

                    { currentForm !== Forms.EMAIL &&
                        <FlexContainer style={{
                            maxWidth: "1200px",
                            backgroundColor: "#FFF",
                            padding: "40px",
                            borderRadius: "12px",
                            border: "1px solid #8291B2"
                        }}>
                            { currentForm === Forms.INITIAL &&
                                <Initial /> }
                            { currentForm === Forms.UPLOAD &&
                                <UploadLogo /> }
                            { currentForm === Forms.COLOR &&
                                <SelectColor /> }
                            { currentForm === Forms.ICON &&
                                <UploadIcon /> }
                            { currentForm === Forms.START_SCREEN &&
                                <StartScreen /> }
                            { currentForm === Forms.FINISH &&
                                <Finish /> }
                        </FlexContainer>
                    }
                </CustomiseContext.Provider>
            </PageContainer>
        );
    }
}

export default Customise;