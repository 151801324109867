import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { ErrorText, Text } from "src/components";
import styled from "styled-components";
import { ErrorList, ErrorStrings } from "../../components/Enums";


const ChangeAsset = styled.div`
    margin-bottom: 20px;
    background-color: black;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    width: 166px;
    height: 38px;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
    z-index: 1;
    position: absolute;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
`;

const BrowseFileButton = styled.div`
    width: 140px;
    height: 37.5px;
    background-color: #000;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    color: #fff;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    font-weight: 400;
`;

const ClickableDivBox = styled.div`
    width: 364px;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E9EBEF;
    border-radius: 15px;
    background-color: #F8F8F8;
    cursor: pointer;
    @media (max-width: 600px) {
        max-width: 220px;
        height: 150px;
    };
`;

const ImageBoxParent = styled.div`
    display: flex;
    position: relative;
    align-items: end;
    justify-content: center;
    @media (max-width: 600px) {
        max-width: 220px;
        height: 150px;
    };
`;

const ImageBox = styled.img`
    width: 364px;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    background-color: #E8E8E8;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    @media (max-width: 600px) {
        max-width: 220px;
        height: 150px;
    };
`;

const AdPrimaryImageUpload = (props) => {
    const { setFormPrimaryAsset, setImageChanged, inputErrors, asset, setFormPrimaryAssetType } = props;
    const [image, setImage] = useState(asset || null);
    const fileInputRef = useRef(null);

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const primaryAssetError = getErrorStrings(ErrorList.EMPTY_PRIMARY_ASSET);

    useEffect(() => {
        if (asset) {
            setImage(asset);
        }
    }, [asset]);

    const handleFiles = (files) => {
        const validImageTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
        const validVideoTypes = ["video/mp4"];
        const maxSizeInBytes = 20 * 1024 * 1024; // 20MB

        if (image) {
            setImage(null);
            setFormPrimaryAsset(null);
        }

        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 20MB. Please select a smaller file.");
                return;
            }

            reader.onload = () => {
                if (validImageTypes.includes(file.type)) {
                    setImage(reader.result);
                    setFormPrimaryAsset(reader.result);
                    setImageChanged(true);
                    setFormPrimaryAssetType("image");
                } else if (validVideoTypes.includes(file.type)) {
                    const video = document.createElement("video");
                    video.className = "videoPreview";
                    video.src = URL.createObjectURL(file);
                    video.crossOrigin = "anonymous";
                    video.muted = true;
                    setFormPrimaryAssetType("video");
                    setFormPrimaryAsset(reader.result);
    
                    video.addEventListener("loadeddata", () => {
                        video.currentTime = 2;
    
                        video.addEventListener("seeked", () => {
                            video.currentTime = 2;
                            const canvas = document.createElement("canvas");
                            canvas.width = video.videoWidth;
                            canvas.height = video.videoHeight;
    
                            const context = canvas.getContext("2d");
                            context.drawImage(video, 0, 0, canvas.width, canvas.height);
    
                            const thumbnail = canvas.toDataURL("image/png");
    
                            setImage(thumbnail);
                            setImageChanged(true);
                        }, { once: true });
                    }, { once: true });
                } else {
                    alert("Invalid file type. Please select an image or video.");
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDropzoneClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFiles(event.dataTransfer.files);
    };

    const handleInputChange = (event) => {
        handleFiles(event.target.files);
    };

    return (
        <div>
            <Text size="16px" align="left" weight="700">
                Upload Primary Asset
            </Text>
            <Text size="14px" color="#612684" align="left" style={{ wrap: "auto" }}>
                Click browse file to upload your image or video.
            </Text>
            
            <input
                type="file"
                accept=".png, .jpg, .jpeg, .mp4"
                ref={fileInputRef}
                onChange={handleInputChange}
                style={{ display: "none" }}
            />
            <ClickableDivBox
                onClick={handleDropzoneClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {image ? (
                    <ImageBoxParent>
                        <ImageBox
                            src={image}
                            alt="Preview"
                        />
                        <ChangeAsset>
                            Change Image/Video
                        </ChangeAsset>
                    </ImageBoxParent>
                ) : (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <p style={{ fontSize: "14px", color: "#808080", maxHeight: "48px", maxWidth: "285px", textAlign: "center" }}>
                            Supported formats: JPG, PNG, MP4. Max size: 20MB. Aspect Ratio 16:9 - 1920px x 1080px
                        </p>
                        <BrowseFileButton>
                            Browse File
                        </BrowseFileButton>
                    </div>
                )}
            </ClickableDivBox>
            { image &&
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "30px" }}>
                    <p style={{ fontSize: "14px", color: "#808080", maxHeight: "48px", maxWidth: "285px", textAlign: "center" }}>
                        Supported formats: JPG, PNG, MP4. Max size: 20MB. Aspect Ratio 16:9 - 1920px x 1080px
                    </p>
                </div>
            }
            
            { primaryAssetError.length > 0 && <ErrorText errorTextAlign="left">{primaryAssetError}</ErrorText> }
        </div>
    );
};

export default AdPrimaryImageUpload;
