import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import {
    FormField, Text, Button, FlexContainer, Toast, PageContainer,
    SplitLayoutRightImage, smallDesktop, mobile,
    CardHeadingLabel, ExplainParagraphLabel, PageHeadingLabel
} from "src/components";
import { Divider } from "@material-ui/core/index";

/* Payment Confirmation */
import PaymentConfirmation from "./PaymentConfirmation.js";
import styled, { css } from "styled-components";
import { ClientLicenseContext as SubscriptionContext } from "./../ClientLicenseContext";
import LeftText from "src/scenes/Subscription/components/LeftText";
import RightImage from "src/img/subscription/4.jpg";
import GreenCheck from "src/img/new/green-check.svg";
import MailIcon from "src/img/new/mail.svg";

export const FormStep = {
    PAGE_PURCHASE_LICENSE: 1,
    PAGE_PAYMENT_CONFIRMATION: 2,
    PAGE_PURCHASE_SUCCESSFUL: 3
};

/* Payment Confirmation */
const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
`;

const StyledButton = styled(Button)`
    font-family: Roboto;
    border-radius: 7px !important;
    width: 8.75rem;
    height: 2.3125rem;
    line-height: 100%;
    font-weight: 500;
    margin-right: 1.125rem;
    margin-top: 1.125rem;
    font-size: 15px;

    ${props => props.disabled && css`
        pointer-events: none;
        opacity: 0.5;
    `};
`;

const ButtonsContainer = styled(FlexContainer)`
    flex-direction: row;
    ${smallDesktop`
        flex-direction: row;
    `};

    ${mobile`
        flex-direction: column;
    `}
`;

// payment success
const CheckIcon = styled.img`
    width: 2.5625rem;
    height: 2.5625rem;
`;

const MailImage = styled.img`
    width: 1.9375rem;
    height: 1.375rem;
    margin: 3.1875rem 0 0.625rem 0;
`;

class PurchaseLicense extends Component {
    state = {
        //global
        purchaseStep: FormStep.PAGE_PURCHASE_LICENSE,
        loadingStatus: false, //who's loading

        //pricing
        licenseCount: 1, 
        shortTermCost: 0.00,
        subtotal: 0.00,
        gst: 0.00,
        currency: null,
        total: 0.00,
        getLicenseCostFromAmountLoading: true,
    };

    nextStep = (stepNumber) => {
        this.setState({ 
            purchaseStep: stepNumber
        });
    };

    updateAmountOfLicense = (event) => {
        const amountOfLicense = Math.max(event.target.value, 1);

        return this.setState({
            licenseCount: amountOfLicense
        });
    };

    getLicenseCostFromAmount = () => {
        this.setState({ 
            getLicenseCostFromAmountLoading: true
        });
        
        const { licenseCount } = this.state;

        axios.get(`${API_URL}/company/licenseCost/${licenseCount}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.setState({
                licenseCount: parseInt(res.data.licenseCount),
                shortTermCost: parseFloat(res.data.shortTermCost),
                subtotal: parseFloat(res.data.subtotal),
                gst: parseFloat(res.data.gst),
                currency: res.data.currency,
                total: parseFloat(res.data.total)
            });
        }).catch(error => {
            return Toast.error(error.message);
        }).finally(res => {
            this.setState({ 
                getLicenseCostFromAmountLoading: false
            });
        });
    };

    /**
     *  Returns a Promise that is being used to `await` in CardList.js thisPurchaseClicked()
    */
    payUsingExistingCard = (selectedMethod, methodSource) => {
        if (methodSource === "square") {
            return this.purchaseWithOurApi(
                `${API_URL}/company/square/moreLicenses`,
                {
                    licenseCount: this.state.licenseCount,
                    cardId: selectedMethod
                });
        } else if (methodSource === "airwallex") {
            return this.purchaseWithOurApi(
                `${API_URL}/company/airwallex/purchaseNow/clientLicenses`,
                {
                    licenseCount: this.state.licenseCount,
                    paymentMethodId: selectedMethod
                });
        } else {
            console.log(`Bad method source: ${methodSource}`);
        }
    };

    payUsingAirwallexPaymentMethod = async (selectedMethod) => {
    };

    purchaseWithOurApi = async (url, requestBody) => {
        this.setState({
            purchaseLicenseLoading: true
        });
        
        return axios.post(url, requestBody, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.setState({
                purchaseStep: FormStep.PAGE_PURCHASE_SUCCESSFUL,
                purchaseLicenseLoading: false,
            });
        }).catch(error => {
            this.setState({
                purchaseLicenseLoading: false
            });
            if (error.response) {
                if (error.response.data.error.messages) {
                    return Toast.error(error.response.data.error.messages.toString());
                }
                return Toast.error(error.response.data.error.toString());
            } else {
                return Toast.error(error.message);
            }
        });
    };

    render() {
        const { user, showPurchaseLicense, getCompanyClientLicense } = this.props;
        const { purchaseStep, licenseCount, shortTermCost, subtotal, gst, currency, total, getLicenseCostFromAmountLoading } = this.state;

        const { companyName, countryCode, abn } = user;
        const disableNext = false;


        if (purchaseStep === FormStep.PAGE_PURCHASE_SUCCESSFUL)
            return (
                <PageCongratulations
                    getCompanyClientLicense={getCompanyClientLicense}
                    showPurchaseLicense={showPurchaseLicense}
                    companyName={companyName}
                    licenseCount={licenseCount}
                    nextStep={this.nextStep} />
            );

        return (
            <>
                { purchaseStep === FormStep.PAGE_PURCHASE_LICENSE &&
                <PageLicenseAmount
                    nextStep={this.nextStep}
                    updateAmountOfLicense={this.updateAmountOfLicense}
                    showPurchaseLicense={showPurchaseLicense}
                    licenseCount={licenseCount}
                    disableNext={disableNext}
                />
                }
                { purchaseStep === FormStep.PAGE_PAYMENT_CONFIRMATION &&
                <Container>
                    <SubscriptionContext.Provider value={{
                        //global
                        token: this.props.user.token,
                        nextStep: this.nextStep,
                        companyName: companyName,
                        abn: abn,
                        countryCode: countryCode,
                        currency: currency,
                        //license price related
                        getLicenseCostFromAmount: this.getLicenseCostFromAmount,
                        licenseCount: licenseCount,
                        shortTermCost: shortTermCost,
                        subtotal: subtotal,
                        gst: gst,
                        total: total,
                        getLicenseCostFromAmountLoading: getLicenseCostFromAmountLoading,

                        //paymentConfirmation card related
                        payUsingExistingCard: this.payUsingExistingCard
                    }}>
                        <PaymentConfirmation />
                    </SubscriptionContext.Provider>
                </Container>
                }
            </>
        );
    }
}

class PageLicenseAmount extends Component {
    render() {
        const { nextStep, updateAmountOfLicense, showPurchaseLicense, licenseCount, disableNext } = this.props;

        return (
            <PageContainer>
                <PageHeadingLabel>
                    Purchase Trial Licenses
                </PageHeadingLabel>
                <ExplainParagraphLabel width="100%" maxWidth="43.125rem" size="0.9375rem">
                    Purchase short-term Client Trial Licenses right here. These licenses allow you to invite clients to experience your services for a limited duration, giving them a taste of what your business has to offer before committing to a long-term license.
                </ExplainParagraphLabel>
                <SplitLayoutRightImage
                    image={RightImage}
                >
                    <CardHeadingLabel margin="0 0 1.75rem 0">Client Trial Licenses</CardHeadingLabel>
                    <Text align="left" weight="700" size="1rem">
                        Enter Amount of Trial Licenses Required
                    </Text>
                    <LeftText color="#8E4EB4" margin="0">
                        *Minimum purchase of 1 license is required.
                    </LeftText>
                    <FormField margin="3.4375rem 0 0 0"
                        height="3.125rem"
                        maxWidth="16.625rem"
                        border="1px solid #000000"
                        borderRadius="12px"
                        size="1.5625rem"
                        placeholderWeight="700"
                        placeholderSize="1.5625rem"
                        onChange={(event) => {updateAmountOfLicense(event);} }
                        maxLength="4"
                        value={licenseCount}
                        style={{ boxShadow: "0px 3px 6px #00000029", fontWeight: 700 }}
                        type="number"
                        min="1"
                    />

                    <Divider style={{ maxWidth: "39.375rem", marginTop: "19.5625rem" }} />
                    <ButtonsContainer direction="row">
                        <StyledButton border="1px solid #000000" color="#000000" onClick={(e) => {showPurchaseLicense(false);}}>
                            Back
                        </StyledButton>
                        <StyledButton color="#ffffff" 
                            backgroundColor="#000000" 
                            onClick={(e) => {nextStep(FormStep.PAGE_PAYMENT_CONFIRMATION);}}
                            disabled={disableNext}
                        >
                            Next
                        </StyledButton>
                    </ButtonsContainer>
                </SplitLayoutRightImage>
            </PageContainer>
        );
    }
}

class PageCongratulations extends Component {
    hideFormAndReloadList = () => {
        const { showPurchaseLicense, getCompanyClientLicense } = this.props;

        showPurchaseLicense(false);
        getCompanyClientLicense(); 
    };

    render() {
        const { companyName, licenseCount } = this.props;

        return (
            <PageContainer>
                <PageHeadingLabel>
                    Your Have Purchased Trial Licenses
                </PageHeadingLabel>
                <ExplainParagraphLabel width="100%" maxWidth="43.125rem" size="0.9375rem">
                    Congratulations! You have Trial Licenses to invite clients to your Me Business app!
                </ExplainParagraphLabel>
                <FlexContainer width="100%" maxWidth="42.125rem" color="#ffffff" marginTop="1.125rem" minHeight="43.625rem" 
                    padding="2.5625rem 0 3.875rem 3.8125rem" style={{ borderRadius: 12 }}
                >

                    <CardHeadingLabel margin="0 0 1.9375rem 0">
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <CheckIcon src={GreenCheck} />
                            <div style={{ marginLeft: "1.0625rem" }}>Purchase Successful!</div>
                        </div>
                        
                    </CardHeadingLabel>
                    <Text size="1rem" weight="700" align="left" margin="0">Business Name:</Text>
                    <Text size="1rem" align="left" margin="0">{ companyName }</Text>

                    <Text size="1rem" weight="700" align="left" margin="1.875rem 0 0 0">Number Of Trial Licenses Purchased:</Text>
                    <Text size="1rem" align="left" margin="0">{ licenseCount }</Text>

                    <MailImage src={MailIcon} />
                    <LeftText color="#612684" size="1rem" margin="0 0 14.3125rem 0" fontWeight="500" maxWidth="26.9375rem">
                        We sent a copy of your purchase receipt to your email inbox. Please check your email to access your receipt and keep it handy for your records.
                    </LeftText>

                    <Button color="#ffffff"
                        backgroundColor="#8E4EB4"
                        width="13.5rem"
                        height="2.375rem"
                        size="0.9375rem"
                        style={{ borderRadius: 7, lineHeight: "100%", textAlign: "center", padding: 0 }}
                        marginTop="1.5rem"
                        onClick={() => this.hideFormAndReloadList()}
                    >
                        Go to Sales & Promotions
                    </Button>
                </FlexContainer>
                
            </PageContainer>
        );
    }
}

export default PurchaseLicense;