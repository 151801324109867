import React, { Component } from "react";
import FlexContainer from "./FlexContainer";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "src/scenes/App";

import MeBusinessLogo from "../img/MeBusiness-BlackBG.png";
import { LicenseRenewalContainerContext } from "./LicenseRenewalContainerContext";
import Text from "./Text";
import { mobile, tablet, desktop } from "./Media";

/**
 * Class: LicenseHeader Styled components
 *  */ 
const Container = styled.header`
background: ${p => p.url ? `url(${p.url}) no-repeat center ${p.location || "top"}` : null};
background-color: ${p => p.transparent ? "transparent" : p.backgroundColor };
flex: 0;
top: ${p => p.top};
transition: background-color 0.3s;
width: 100%;
`;

const Content = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
padding: 24px 32px;
width: 100%;
text-align: center;
`;

const Logo = styled.div`
`;

/**
 * Class: LicenseFooter Styled components
 *  */ 

const FooterContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 14px;
margin-top: 32px;
text-align: center;
width: 90%;
padding-top: 10px;

${desktop`
    flex-direction: row;
    height: 6em;
    margin-top: 0;
    padding-bottom: 0;
    
`};
`;

const FooterLinksContainer = styled.div`
display: flex;
flex-direction: column;
flex-grow: 1;
justify-content: center;
align-items: center;

${desktop`
    flex-direction: row;
`};

`;

const FooterLink = styled.a`
color: #000;
font-weight: 500;
padding: 16px 0;
text-decoration: none;
width: 12em;

${tablet`
    flex-grow: 1;
    width: 100%;
    
`};

${desktop`
    flex-grow: 0;
    padding: 16px 16px;
    width: 8em;
`};

`;

const OphPowerContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align:left;

${mobile`
    display: table-cell;
    text-align:center;
    padding-bottom: 20px;
`};

`;

const OphImage = styled.img`
    width: auto;
    height: 8em;
    margin: 1em;

    ${desktop`
        height: 4em;
    `};
`;

const OphFooterImage = styled.img`
width: auto;
height: 1.5rem;
margin: 1em;
`;

const OphLink = styled.a`
text-decoration: none;
`;

class LicenseRenewalContainer extends Component {
    state = {
        fetching: 0,
        businessLogo: undefined,
        backgroundColor: "#000000",
        licenseTermDuration: undefined,
        licenseTermDurationUnit: undefined,
        licenseTermAmount: undefined,
        isAustralian: undefined,
        gstAmount: undefined,
        currency: undefined,
        chargeAmount: undefined,
        accessToken: "XXXXXX",
        employeeName: "Me App User",
        businessName: "On Point Holdings",
        isValidLicenseRenewalKey: false,
        key: undefined,
        referralCode: undefined,
        errorMessage: undefined,
        licenseStatus: undefined,
        finishDate: undefined,
    };
    
    componentDidMount() {
        this.setState(state => ({
            fetching: state.fetching + 1
        }), () => this.safeToInit());
    }
    
    safeToInit = () => {
        const referralCode = this.props.match.params.referralCode;
        const key = this.props.match.params.key;

        axios.get(`${API_URL}/validateLicenseRenewalKey/${referralCode}/${key}`)
            .then(res => {
                const validateLicenseRenewalResponse = res.data;

                this.setState(({
                    isValidLicenseRenewalKey: true,
                    employeeName: validateLicenseRenewalResponse.employeeName,
                    businessName: validateLicenseRenewalResponse.businessName,
                    businessLogo: this.generateImageContainer(validateLicenseRenewalResponse.businessLogo),
                    backgroundColor: validateLicenseRenewalResponse.backgroundColor,
                    licenseTermDuration: validateLicenseRenewalResponse.licenseTermDuration,
                    licenseTermDurationUnit: validateLicenseRenewalResponse.licenseTermDurationUnit,
                    licenseTermAmount: validateLicenseRenewalResponse.licenseTermAmount,
                    isAustralian: validateLicenseRenewalResponse.isAustralian,
                    gstAmount: validateLicenseRenewalResponse.gstAmount,
                    currency: validateLicenseRenewalResponse.currency,
                    chargeAmount: validateLicenseRenewalResponse.chargeAmount,
                    accessToken: validateLicenseRenewalResponse.accessToken,
                    licenseStatus: validateLicenseRenewalResponse.licenseStatus,
                    finishDate: validateLicenseRenewalResponse.finishDate,
                    key: key,
                    referralCode: referralCode,
                }));
            }).catch(error => {
                if (error.response) { 
                    this.setState(state => ({
                        errorMessage: error.response.data["error"],
                    }));
                } else {
                    console.log(error);
                    this.setState(state => ({
                        errorMessage: "The user you have requested cannot be found",
                    }));
                }
            }).finally(() => {
                this.setState(state => ({
                    fetching: state.fetching - 1,
                }));
            });
    };
    
    generateImageContainer = (imageUrl) => {
        return (
            <FlexContainer direction="row" alignItems="center">
                <img src={imageUrl} alt={"Company Logo"} style={{ height: "29px", objectFit: "contain" }} />
            </FlexContainer>
        );
    };

    render() {
        const { children } = this.props;

        if (this.state.fetching > 0) {
            return (
                <FlexContainer padding="2rem 2rem" style={{ margin: "auto" }}>
                    <Text size="30px" color="#000" weight="500">
                        Renew License
                    </Text>
    
                    <Text margin="10px" size="16px" color="#000" weight="500">
                        Please wait while we connect you to your account...
                    </Text>
                </FlexContainer>
            );
        }

        return (
            <FlexContainer color="#FFF" family="Roboto, Helvetica, sans-serif">
                
                <LicenseHeader logoImg={this.state.businessLogo} backgroundColor={this.state.backgroundColor} />

                { !this.state.isValidLicenseRenewalKey && <UserCannotBeFound errorMessage={this.state.errorMessage} /> }

                { this.state.isValidLicenseRenewalKey &&
                    <LicenseRenewalContainerContext.Provider value={{
                        businessLogo: this.state.businessLogo,
                        backgroundColor: this.state.backgroundColor,
                        licenseTermDuration: this.state.licenseTermDuration,
                        licenseTermDurationUnit: this.state.licenseTermDurationUnit,
                        licenseTermAmount: this.state.licenseTermAmount,
                        isAustralian: this.state.isAustralian,
                        gstAmount: this.state.gstAmount,
                        currency: this.state.currency,
                        chargeAmount: this.state.chargeAmount,
                        accessToken: this.state.accessToken,
                        key: this.state.key,
                        referralCode: this.state.referralCode,
                        employeeName: this.state.employeeName,
                        businessName: this.state.businessName,
                        licenseStatus: this.state.licenseStatus,
                        finishDate: this.state.finishDate
                    }}>
                        {children}
                    </LicenseRenewalContainerContext.Provider>
                }

                <LicenseFooter />
            </FlexContainer>
        );
    }
}

class UserCannotBeFound extends Component {
    render() {
        return (
            <FlexContainer style={{ margin: "auto" }}>
                <Text size="30px" color="#000" weight="500">
                    Renew License
                </Text>

                <Text margin="10px" size="16px" color="#000" weight="500">
                    {this.props.errorMessage}
                </Text>
            </FlexContainer>
        );
    }
}

class LicenseHeader extends Component {
    render() {
        const { top, url, transparent, logoImg, backgroundColor } = this.props;

        return (
            <Container transparent={transparent} top={top} url={url} backgroundColor={backgroundColor}>
                <Content>
                    <Logo>
                        {logoImg}
                    </Logo>
                </Content>
            </Container>
        );
    }
}

class LicenseFooter extends Component {
    render() {
        return (
            <div style={{ background: "#fff", width: "100%", justifyContent: "center", display: "flex" }} >
                <FooterContainer>
                    <OphPowerContainer>
                        <OphLink href="https://www.onpoint-holdings.com" target="_blank" rel="noopener noreferrer">
                            <OphImage src={MeBusinessLogo} />
                        </OphLink>

                        <div>
                            <div>Powered by Me.</div>
                            <div>A Division of On Point Holdings.</div>
                        </div>
                    </OphPowerContainer>

                    <FooterLinksContainer>
                        <FooterLink href="https://www.memotivationapp.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                            Terms &amp; Conditions
                        </FooterLink>
                        <FooterLink href="https://www.memotivationapp.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </FooterLink>
                        <FooterLink href="mailto:support@memotivationapp.com">
                            Contact Us
                        </FooterLink>
                    </FooterLinksContainer>
                    
                    <OphPowerContainer>
                        <OphFooterImage src="https://s3.ap-southeast-2.amazonaws.com/dailyfixme.content/template/onpoint_logo.png" />
                    </OphPowerContainer>
                </FooterContainer>
            </div>
        );
    }
}

export default LicenseRenewalContainer;