import React, { useState, useEffect, useRef } from "react";
import { Button, Text, LoadingIndicator, Toast } from "src/components";
import { Dialog, Box, Divider, Grid } from "@material-ui/core";
import styled from "styled-components";
import { AUDIENCE } from "../Advertising";
import DropdownIcon from "src/img/new/drop-down.svg";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import ViewersListPreview from "./AdvertisingModalTable";

const ModalFilter = styled.select`
    height: 2.438rem;
    width: 11.125rem;
    border-radius: 7px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000;
    padding: 0.3em 1.1em;
    color: #000;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;

    &:focus {
        outline: none;
    }
`;

const getAdvertisingClicks = (token, ad) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const [advertisingClicks, setAdvertisingClicks] = useState([]);
    const [isFetchingClicks, setIsFetchingClicks] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            setIsFetchingClicks(true);
            try {
                const config = {
                    signal: controller.signal,
                    headers: {
                        Authorization: "Bearer " + token
                    }
                };

                const response = await axios.get(`${API_URL}/advertising/${ad.uuid}/clicks`, config);
                
                if (isMountedRef.current) {
                    setAdvertisingClicks(response.data.clicks);
                }
            } catch (error) {
                if (isMountedRef.current) {
                    Toast.error("Unable to fetch advertisement. Please try again later or contact support if the issue persists.");
                }
            } finally {
                if (isMountedRef.current) {
                    setIsFetchingClicks(false);
                }
            }
        };

        fetch();

        return () => {
            controller.abort();
            isMountedRef.current = false;
        };

    }, [token]);

    return { advertisingClicks, isFetchingClicks };
};

const AdvertisingClicksModal = ({ adData, showAdClicksModal, handleCloseAdClicksModal, user }) => {
    const { advertisingClicks, isFetchingClicks } = getAdvertisingClicks(user.token, adData);
    
    const userTypeDefault = () => {
        if (adData.displayToEmployees) {
            return AUDIENCE.EMPLOYEE;
        } else if (adData.displayToClients) {
            return AUDIENCE.CLIENT;
        } else {
            return AUDIENCE.EMPLOYEE; //default to employees
        }
    };

    const filterClicksByUserType = (userType) => {
        return userType === AUDIENCE.EMPLOYEE ? 
            advertisingClicks.employees : 
            advertisingClicks.clients;
    };

    const [userType, setUserType] = useState(userTypeDefault());
    const [viewersList, setViewersList] = useState(null);

    const handleUserTypeChange = (e) => {
        const value = e.target.value;
        setUserType(value);
    };

    useEffect(() => {
        const filteredList = filterClicksByUserType(userType);
        setViewersList(filteredList);
    }, [isFetchingClicks]);
    
    useEffect(() => {
        const filteredList = filterClicksByUserType(userType);
        setViewersList(filteredList);
    }, [userType]);

    const getTotalLinkClicks = (viewersList) => {
        if (viewersList.length === 0)
            return 0;

        return viewersList.map(employeeDetails => employeeDetails.numberOfClicks).reduce((total, numberOfClicks) => total + numberOfClicks, 0);
    };
    
    const clickCount = viewersList ? getTotalLinkClicks(viewersList) : 0;
    const clicksCountFormatted = `${parseInt(clickCount).toLocaleString("en-us")} ${clickCount > 1 || clickCount === 0 ? "Clicks" : "Click"}`;

    return (
        <Dialog
            open={showAdClicksModal}
            onClose={handleCloseAdClicksModal}
            maxWidth="md"
            scroll="body"
            PaperProps={{
                style: {
                    width: 980,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "1.563rem 3.125rem 0.625rem" }}>
                <Grid container direction="row" width="100%">
                    <Grid item md={6} sm={6} xs={12}>
                        <Text size="2.313rem" weight="700" align="left" margin="0">
                            Ad Clicks
                        </Text>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={{ textAlign: "right" }}>
                        <ModalFilter value={userType} onChange={handleUserTypeChange}>
                            <option value={AUDIENCE.EMPLOYEE}>Employees</option>
                            <option value={AUDIENCE.CLIENT}>Clients</option>
                        </ModalFilter>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <Text size="1.25rem" weight="700" align="left" margin="0px 0px 12px 0px">View Ad Clicks</Text>
                <Grid container>
                    <Grid item md={9}>
                        <Text size="16px" align="left" margin="0">
                            Review detailed click data from either employees or clients to measure
                        </Text>
                        <Text size="16px" align="left" margin="0">
                            the effectiveness and interaction level of your ad campaign.
                        </Text>
                    </Grid>
                    <Grid item md={3}>
                        { !isFetchingClicks && <Text size="2.313rem" weight="700" align="right" margin="0">{clicksCountFormatted}</Text>}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <>  
                    { isFetchingClicks 
                        ? <LoadingIndicator height="1.563rem" width="1.563rem" containerHeight="300px" /> 
                        : <ViewersListPreview viewersList={viewersList} isReaderTypeEmployee={userType === AUDIENCE.EMPLOYEE} modalType="Clicks" />
                    }   
                </>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem 2.188rem" }}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ textAlign: "right" }}>
                        <>
                            <Button
                                onClick={handleCloseAdClicksModal}
                                size="0.875rem"
                                border="1px solid #000"
                                color="#000"
                                width="8.75rem"
                                style={{ borderRadius: "7px", boxShadow: "none" }}
                            >
                                Cancel
                            </Button>
                        </>
                    </div>
                </div>
            </Box>
        </Dialog>
    );
};

export default AdvertisingClicksModal;