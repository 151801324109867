import React from "react";
import { Dialog, Box, Divider } from "@material-ui/core/";
import { Button } from "src/components";
import styled from "styled-components";

const Heading = styled.div`
    color: #11141A;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    font-family: Roboto, Helvetica, sans-serif;
`;

const Description = styled.div`
    color: #000000;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    margin-top: 1.5625rem;
`;

const InviteSuccessModal = (props) => {
    const { showDialog, handleClose, type, isEitherInviteSuccessful } = props;
    
    const headings = {
        email: "Email App Invite Successfully Sent!",
        sms: "SMS App Invite Successfully Sent!",
        both: (<>
            Email & SMS <br />
            App Invite Successfully Sent!
        </>)
    };

    const renderTopBoxMargin = () => {
        let margins = 0;
        if (["email", "sms"].includes(type)) {
            margins = "12.125rem 8.3125rem 5.09rem 8.3125rem";
        }
        else if (type === "both") {
            margins = "9.1875rem 8.3125rem 5.09rem 8.3125rem";
        }

        return margins;
    };

    return (
        <Dialog open={showDialog} onClose={handleClose}
            maxWidth="md"
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: "56.25rem",
                    borderRadius: 15,
                    overflow: "auto",
                    fontFamily: "Roboto, Helvetica, sans-serif"
                },
            }}
        >
            { isEitherInviteSuccessful ? 
                <Box sx={{ margin: renderTopBoxMargin() }}>
                    <Heading>{ headings[type] }</Heading>
                    <Description>
                        A personalised invitation to access your customised business Me app has been<br />
                        sent to your selected users via email. This email will contain a unique code that<br />
                        will enable them to join your business app.
                    </Description>
                </Box> 
                :
                <Box sx={{ margin: renderTopBoxMargin() }}>
                    <Heading>Invite Failed</Heading>
                    <Description>
                        There was an issue with the invite, try again later.
                    </Description>
                </Box>
            }


            <Divider />
            <Box sx={{ textAlign: "center", marginTop: "2.1875rem", marginBottom: "2em" }}>
                <Button 
                    border="1px solid #000"
                    color="#000"
                    marginRight="15px"
                    width="8.75rem"
                    onClick={handleClose}
                    style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                    className="form-btns disabled"
                >
                    Done
                </Button>
            </Box>
        </Dialog>
    );
};

export default InviteSuccessModal;