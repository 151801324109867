import React, { useContext, useState } from "react";
import styled from "styled-components";

import ImgBackground from "src/img/customise/ImgBackground.png";

import ImgPhoneFrame from "src/img/customise/ImgPhoneFrame.png";
import ImgYourLogoHere from "src/img/customise/ImgYourLogoHere.png";

import ImgQuotesOverlay from "src/img/customise/ImgQuotesOverlay.png";
import ImgQuotesPrimary from "src/img/customise/ImgQuotesPrimary.png";
import ImgQuotesSecondary from "src/img/customise/ImgQuotesSecondary.png";

import ImgArticlesPrimary from "src/img/customise/ImgArticlesPrimary.png";

import ImgNoticeboardBackgroundOverlay from "src/img/customise/ImgNoticeboardBackgroundOverlay.png";
import ImgNoticeboardPrimary from "src/img/customise/ImgNoticeboardPrimary.png";
import { Screens } from "../../components/Enums";
import { AdFormContext } from "../AdFormContext";
import { Text } from "src/components";

const LayerSizing = {
    FRAME_WIDTH: 302,
    FRAME_HEIGHT: 614,
    SCREEN_WIDTH: 302,
    SCREEN_HEIGHT: 594
};

const WidthContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
`;

const ZeroSizeSvg = styled.svg`
    width: 0px;
    height: 0px;
    position: absolute;
    pointer-events: none;
`;

const PlainImageLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top};
    bottom: ${ p => p.layout.bottom };
    background-image: url(${ p => p.src });
    background-color: ${ p => p.bgColor };
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${p => p.layout.borderRadius}px;
    pointer-events: none;
`;

const InteractiveLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top};
    bottom: ${ p => p.layout.bottom };
    background-image: url(${ p => p.src });
    background-color: ${ p => p.bgColor };
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${p => p.layout.borderRadius}px;
`;

const PlainImageTextLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top}px;
    background-image: url(${ p => p.src });
    background-size: contain;
    background-position: ${p => p.position || "center"};
    background-repeat: no-repeat;
    pointer-events: none;
    &:after {
        position: absolute; 
        top: 0; 
        left: 0; 
        bottom: 0; 
        right: 0;
        margin-top: 40px; 
        z-index: 99999; 
        font-size: 16px; 
        color: #FFFFFF;
        text-align: center;
        text-shadow: 0px 0px 5px black;
        padding: 5px;
    }
`;

const BackgroundLayer = styled(PlainImageLayer)`
    filter: url(#filter-background);
    pointer-events: none;
`;

const PrimaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-primary);
    pointer-events: none;
`;

const SecondaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-secondary);
    pointer-events: none;
`;

const LinkButton = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    font-family: Arial, sans-serif;
    font-size: ${p => p.size};
    font-weight: 500;
    text-align: center;
    border-radius: 24px;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
`;

const AdBadge = styled.div`
    width: ${p => p.width ?? "43px"};
    height: ${p => p.height ?? "23px"};
    border-radius: 5px;
    border: 1px solid #9B9B9B;
    color: #9B9B9B;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBEBEB;
    text-align: center;
    size: ${p => p.size};
    wieght: 700;
`;

const AdBadgeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -12px;
    z-index: 10;
    width: 100%;
`;

const AdImageContainer = styled.div`
    height: ${p => p.height};
    width: 100%;
    background-color: #e5e5e5;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;
`;

const NoticeboardLinkButton = styled.div`
    display: inline-flex;
    width: 70%;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    font-family: Arial, sans-serif;
    font-size: ${p => p.size};
    font-weight: 500;
    text-align: center;
    border-radius: 24px;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
`;

const VideoMuteButton = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: rgba(255,255,255,0.25);
    position: absolute;
    display: flex;
    right: 10px;
    top: 10px;
    color: #fff;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 8px;
    cursor: pointer;
    z-index: 1;
`;

const SvgFilter = (props) => {
    const { filterId, rgbValues } = props;
    const matrix = `0 0 0 0 ${rgbValues[0]} 0 0 0 0 ${rgbValues[1]} 0 0 0 0 ${rgbValues[2]} 0 0 0 1 0`;
    return (
        <filter id={filterId} colorInterpolationFilters="sRGB">
            <feColorMatrix type="matrix" values={matrix} />
        </filter>
    );
};

const renderLayers = (currentScreen, logoUrl, iconUrl) => {
    const logoAspect = 3.43;
    const logoSize = {
        width: LayerSizing.FRAME_WIDTH / 2,
        height: Math.floor(LayerSizing.FRAME_WIDTH / 2 / logoAspect)
    };

    const phoneLayout = {
        width: LayerSizing.FRAME_WIDTH,
        height: LayerSizing.FRAME_HEIGHT,
        left: 0,
        top: 0
    };
    const screenLayout = {
        width: LayerSizing.SCREEN_WIDTH,
        height: LayerSizing.SCREEN_HEIGHT,
        left: (LayerSizing.FRAME_WIDTH - LayerSizing.SCREEN_WIDTH) / 2,
        top: (LayerSizing.FRAME_HEIGHT - LayerSizing.SCREEN_HEIGHT) / 2
    };
    const quotesScreenLayout = {
        width: LayerSizing.SCREEN_WIDTH,
        height: LayerSizing.SCREEN_HEIGHT,
        left: (LayerSizing.FRAME_WIDTH - LayerSizing.SCREEN_WIDTH) / 2,
    };
    const fullscreenQuoteLayout = {
        width: LayerSizing.SCREEN_WIDTH - 30,
        height: LayerSizing.SCREEN_HEIGHT - 65,
        bottom: "0",
    };
    const logoLayout = {
        width: logoSize.width,
        height: logoSize.height - (logoSize.height / 5),
        left: (LayerSizing.FRAME_WIDTH / 12) / 2,
        top: (currentScreen === Screens.QUOTES || currentScreen === Screens.FULLSCREEN_QUOTE ? LayerSizing.FRAME_HEIGHT / 8.5 : LayerSizing.FRAME_HEIGHT / 2.2) - logoSize.height / 2
    };  
    
    const hasCompanyLogo = logoUrl.charAt(0) !== "?";
    const companyLogo = hasCompanyLogo ? logoUrl : ImgYourLogoHere;
    const imageLayers = [];
    switch (currentScreen) {
        case Screens.QUOTES:
            imageLayers.push(<BackgroundLayer key="MAIN_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer key="MAIN_PrimaryLayer" layout={screenLayout} src={ImgQuotesPrimary} />);
            imageLayers.push(<SecondaryLayer key="MAIN_SecondaryLayer" layout={screenLayout} src={ImgQuotesSecondary} />);
            imageLayers.push(<PlainImageLayer key="MAIN_PlainImageLayer_screen" layout={screenLayout} src={ImgQuotesOverlay} />);
            imageLayers.push(QuotesScreenContent(quotesScreenLayout));
            imageLayers.push(<PlainImageTextLayer position="left" key="MAIN_PlainImageTextLayer" layout={logoLayout} src={companyLogo} />);
            imageLayers.push(<PlainImageLayer key="MAIN_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.FULLSCREEN_QUOTE:
            imageLayers.push(<BackgroundLayer key="ADS_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer key="ADS_PrimaryLayer" layout={screenLayout} src={ImgArticlesPrimary} />);
            imageLayers.push(FullscreenQuoteContent(fullscreenQuoteLayout));
            imageLayers.push(<PlainImageLayer key="ADS_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.NOTICEBOARD:
            imageLayers.push(<BackgroundLayer key="NOTICEBOARD_BackgroundLayer" layout={screenLayout} src={ImgNoticeboardBackgroundOverlay} />);
            imageLayers.push(NoticeboardScreenContent(quotesScreenLayout));
            imageLayers.push(<PrimaryLayer key="NOTICEBOARD_PrimaryLayer" layout={screenLayout} src={ImgNoticeboardPrimary} />);
            imageLayers.push(<PlainImageLayer key="NOTICEBOARD_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        default:
            break;
    }

    return imageLayers;
};

const renderImagePreview = (asset, width) => {
    return (
        <img
            src={asset}
            alt="Ad Preview"
            style={{
                width: width,
                height: "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "inherit",
                backgroundColor: "#E8E8E8",
                cursor: "pointer",
                objectFit: "cover",
                objectPosition: "center"
            }}
        />
    );
};

const renderVideoPreview = (asset) => {
    const [muted, setMuted] = useState(true);
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            borderRadius: "inherit"
        }}>
            <VideoMuteButton 
                onClick={() => setMuted(!muted)}
            >
                {muted ? "M" : "UM"} { /* will update this asset once john provides them, M is for Muted UM is for Unmuted */ }
            </VideoMuteButton>
            <video
                controls={false}
                muted={muted}
                autoPlay={true}
                loop={true}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit"
                }}
            >
                <source src={asset} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

const FullscreenQuoteContent = (fullscreenQuoteLayout) => {
    const {
        headlineContext,
        descriptionContext,
        buttonTextContext,
        formPrimaryAssetContext,
        formPrimaryAssetChangedContext,
        formPrimaryAssetTypeContext,
        isEditForm
    } = useContext(AdFormContext);
    return (
        <InteractiveLayer key="ADS_InteractiveLayer_screen" layout={fullscreenQuoteLayout} bgColor="#fff" >
            <AdImageContainer height="140px">
                { ((formPrimaryAssetContext && formPrimaryAssetChangedContext) || isEditForm) && (
                    <>
                        {
                            formPrimaryAssetTypeContext === "image" ?
                                renderImagePreview(formPrimaryAssetContext, "100%") : 
                                renderVideoPreview(formPrimaryAssetContext)
                        }
                    </>
                )}
                <AdBadgeContainer>
                    <AdBadge size="12px">
                        AD
                    </AdBadge>
                </AdBadgeContainer>
            </AdImageContainer>
            <Text color="#000" size="20px" weight="700">{ headlineContext ? headlineContext : "Heading here" }</Text>
            <Text color="#000" size="16px">{ descriptionContext ? descriptionContext : "Description text here" }</Text>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", position: "absolute", bottom: 60, width: "100%" }}>
                <LinkButton size="16px">{buttonTextContext ? buttonTextContext : "Call to action"}</LinkButton>
            </div>
        </InteractiveLayer>
    );
};

const QuotesScreenContent = (layout) => {
    const {
        headlineContext,
        descriptionContext,
        buttonTextContext,
        formPrimaryAssetContext,
        formPrimaryAssetChangedContext,
        formPrimaryAssetTypeContext,
        isEditForm
    } = useContext(AdFormContext);
    return (
        <InteractiveLayer key="ADS_InteractiveLayer_screen" layout={layout} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "165px", height: "305px", display: "flex", flexDirection: "column", backgroundColor: "#fff", borderRadius: "25px" }}>
                <AdImageContainer height="100px">
                    { ((formPrimaryAssetContext && formPrimaryAssetChangedContext) || isEditForm) && (
                        <>
                            {
                                formPrimaryAssetTypeContext === "image" ?
                                    renderImagePreview(formPrimaryAssetContext, "100%") :
                                    renderVideoPreview(formPrimaryAssetContext)
                            }
                        </>
                    )}
                    <AdBadgeContainer>
                        <AdBadge size="8px" width="40px" height="18px">
                            AD
                        </AdBadge>
                    </AdBadgeContainer>
                </AdImageContainer>
                <Text color="#000" size="15px" weight="700" margin="16px 0 5px 0">{ headlineContext ? headlineContext : "Heading here" }</Text>
                <Text color="#000" size="14px" margin="0 3px">{ descriptionContext ? descriptionContext : "Description text here" }</Text>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "inherit", position: "absolute", bottom: "160px" }}>
                    <LinkButton size="14px">{buttonTextContext ? buttonTextContext : "Call to action"}</LinkButton>
                </div>
            </div>
        </InteractiveLayer>
    );
};

const NoticeboardScreenContent = (layout) => {
    const {
        headlineContext,
        descriptionContext,
        buttonTextContext,
        formPrimaryAssetContext,
        formPrimaryAssetChangedContext,
        formPrimaryAssetTypeContext,
        isEditForm
    } = useContext(AdFormContext);
    return (
        <InteractiveLayer key="ADS_InteractiveLayer_screen" layout={layout} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "240px", height: "280px", display: "flex", flexDirection: "column", backgroundColor: "rgb(255, 255, 255)", borderRadius: "24px", border: "1px solid #E0E0E0" }}>
                <AdImageContainer height="100px">
                    { ((formPrimaryAssetContext && formPrimaryAssetChangedContext) || isEditForm) && (
                        <>
                            {
                                formPrimaryAssetTypeContext === "image" ?
                                    renderImagePreview(formPrimaryAssetContext, "100%") :
                                    renderVideoPreview(formPrimaryAssetContext)
                            }
                        </>
                    )}
                    <AdBadgeContainer>
                        <AdBadge size="12px">
                            AD
                        </AdBadge>
                    </AdBadgeContainer>
                </AdImageContainer>
                <Text color="#000" size="15px" align="left" weight="700" margin="10px 0 0 17px">{ headlineContext ? headlineContext : "Heading here" }</Text>
                <Text color="#000" size="14px" align="left" margin="0 0 0 17px">{ descriptionContext ? descriptionContext : "Description text here" }</Text>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "inherit", position: "absolute", bottom: "170px" }}>
                    <NoticeboardLinkButton size="14px">{buttonTextContext ? buttonTextContext : "Call to action"}</NoticeboardLinkButton>
                </div>
            </div>
        </InteractiveLayer>
    );
};

const FilteredPngStack = (props) => {
    const { currentScreen, colourPalette, logoUrl, iconUrl } = props;
    const imageLayers = renderLayers(currentScreen, logoUrl, iconUrl);
    const { primaryColor, secondaryColor, backgroundColor } = colourPalette;

    return (
        <WidthContainer>
            <ZeroSizeSvg>
                <SvgFilter filterId="filter-primary" rgbValues={primaryColor} />
                <SvgFilter filterId="filter-secondary" rgbValues={secondaryColor} />
                <SvgFilter filterId="filter-background" rgbValues={backgroundColor} />
            </ZeroSizeSvg>
            {imageLayers}
        </WidthContainer>
    );
};

export default FilteredPngStack;
