import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import "moment/min/locales";
import { API_URL } from "src/scenes/App";
import { formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import {
    LoadingIndicator, Toast, Modal, PageContainer, PageHeadingLabel
} from "src/components";

import "src/stylesheets/employeelist.css";
import UserListsTable from "./components/UserListsTable";
import UserRoleAndPermissionsForm from "./components/UserRoleAndPermissionsForm";
import SendInviteFormModal from "src/components/AppInvite/SendInviteFormModal";
import AddNewUserOptionsModal from "./components/AddNewUserOptionsModal";
import UploadUserListModal from "./components/UploadUserListModal";
import EditUserListModal from "./components/EditUserListModal";
import UserDetailsForm from "./components/UserDetailsForm";
import UserFormSuccessModal from "./components/UserFormSuccessModal";

import { AUSTRALIA } from "src/constants/subscriptions";
import { NO_ROLE } from "src/constants/premissionRoles";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import { capitalizeString } from "src/utils/helpers";
import { getCountryCallingCodeWithPlusSign } from "./components/UploadUserListModal";


export const PageType = {
    EMPLOYEE: 1,
    CLIENT: 2
};

const InputSelector = styled.select`
    background: #51315D;
    border:0;
    text-decoration: none;
    color: white;
`;

export const SortableColumns = {  
    FIRST_NAME: 0,
    LAST_NAME: 1,
    EMAIL: 2,
    DEPARTMENT: 3,
    JOB_TITLE: 4, 
    USER_STATUS: 5,
};

export const SortableColumnState = {
    INACTIVE: "INACTIVE",
    DESCENDING: "DESCENDING",
    ASCENDING: "ASCENDING",
};

const DirtyState = {
    FALSE: 0,
    TRUE: 1,
    UNKNOWN: 2,
};

class EmployeeList extends Component {
    static contextType = UserContext;
    state = {
        fetching: 0,
        fetchingCsv: 0,
        employeeArray: [],
        uploadUsersArray: [],
        isUserUploadAClient: false,
        companyName: "",
        companyPhoneNumber: "",
        isCompanyAustralian: false,
        startDate: new Date(),
        subscriptionPeriod: 0,
        totalUsers: 125,
        existedUsers: 0,
        totalEmployees: 0,
        newFirstName: "",
        newLastName: "",
        newTitle: "",
        newDepartment: "",
        newBirthday: "",
        newMobile: "",
        newEmail: "",
        newPermission: false,
        redirect: false,
        isSentSmsInvitation: false,
        isSentEmailInvitation: false,
        errorList: [],
        sortableColumnStates: [],
        dirtyRows: 0,
        countryCallingCode: "",
        isLocaleSet: false,
        showUserPermissionModal: false,
        showUserDetailsFormModal: false,
        showUserFormSuccessModal: false,
        showSendInviteFormModal: false,
        isShowAddNewUserOptions: false,
        isShowUploadUserListModal: false,
        isShowEditUserListModal: false,
        existingUserEmailsResponse: {}, //response from /existingUserEmails route
        permModalData: {
            userUuid: null,
            userName: null,
            role: null,
            permissions: null,
            leadershipRoles: []
        },
        userFormData: {
            formType: "add",
            uuid: null,
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            employmentPosition: null,
            department: null,
            isClient: null
        },
        inviteFormData: {
            users: []
        },
        userNewToken: {},
        userType: PageType.EMPLOYEE,
    };

    componentDidMount() {
        const { isClient } = this.props;
        const userType = isClient ? PageType.CLIENT : PageType.EMPLOYEE;
        this.getCountryCallingCode();
        this.getCompany();
        this.setState(state => ({
            fetching: state.fetching + 1,
            userType: userType
        }), () => this.safeToInit());
        this.setColumns();
    }

    safeToInit = () => {
        setTimeout(() => {
            if (this.state.isLocaleSet) {
                this.getEmployees();
                this.setState(state => ({
                    fetching: state.fetching - 1
                }));
            } else {
                this.safeToInit();
            }
        }, 25);
    };

    // No need to set state fetching, this function is only setting up the sortable columns array.
    setColumns = () => {
        let columns = [];
        for (let i = 0; i <= SortableColumns.USER_STATUS; i++) {
            columns.push(SortableColumnState.INACTIVE);
        }

        this.setState(state => ({
            sortableColumnStates: columns,
        }));
    };

    getCompany = () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}/company`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.setState(state => ({
                fetching: state.fetching - 1,
                companyName: res.data.companyName,
                totalEmployees: res.data.totalNumber,
                isCompanyAustralian: res.data.countryCode === AUSTRALIA,
                companyPhoneNumber: res.data.phoneNumber,
            }));
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    getSuffix = () => {
        const isClient = this.isShowingClients();

        //detect if it is coming from employee or client
        let suffix;
        if (isClient) {
            suffix = "clients";
        } else {
            suffix = "employees";
        }

        return suffix;
    };


    doesEmployeeHaveError = (employee) => {
        return employee.invalidData != null && Object.keys(employee.invalidData).length > 0;
    };

    /**
     * 3-step process setting a default country code.
     * 1. Asking the API what our calling code should be based on IP.
     * 2. Seeing what country code the company account's phone number has.
     * 3. Assume Australia (+61) if company 'isAustralian' or American (+1) if company is not.
     */
    getCountryCallingCode = () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        // Getting the country code of the user.
        axios.get(`${API_URL}/callingCode`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            // Step 1 (Get calling code from API)
            let callingCode = res.data.callingCode;

            if (callingCode == null) {
                let phoneNumber = parsePhoneNumber(this.state.companyPhoneNumber);

                // Step 2 (Checking the code of the company phone)
                if (phoneNumber) {
                    callingCode = phoneNumber.countryCallingCode;

                // Step 3 (Predefined Assumption)
                } else {
                    callingCode = this.state.isCompanyAustralian ? "61" : "1";
                }
            }

            this.setState({
                countryCallingCode: callingCode
            }, () => {
                if (this.state.countryCode) {
                    moment.locale(this.state.countryCode);
                } else {
                    moment.locale(navigator.language);
                }
            });
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
                isLocaleSet: true,
            }));
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    isShowingClients = () => this.state.userType !== PageType.EMPLOYEE;

    getEmployees = () => {
        const isClient = this.isShowingClients();
        let suffix = this.getSuffix();
        this.setState(state => ({
            fetching: state.fetching + 1,
            employeeArray: [],
            dirtyRows: 0
        }));

        axios.get(`${API_URL}/${suffix}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            let companyName = res.data.companyName;
            let totalUsers = res.data.totalUsers;
            let existedUsers = res.data.existedUsers;
            //calculate remaining days
            let startDate = res.data.subscriptionStart;
            let subscriptionPeriod = res.data.subscriptionPeriod;

            if (isClient) {
                let allClients = res.data.clients;

                allClients && allClients.forEach(element => {
                    let formattedNumber = "";
                    if (element.phoneNumber && element.phoneNumber.length > 0) {
                        formattedNumber = formatPhoneNumberIntl(element.phoneNumber);
                    }
                    element.phoneNumber = formattedNumber.length > 0 ? formattedNumber : element.phoneNumber;
                    element.isDirty = DirtyState.FALSE;
                    element.invalidData = {};
                    if (element.dateOfBirth) {
                        element.dateOfBirth =
                            moment(element.dateOfBirth, ["L", moment.HTML5_FMT.DATE])
                                .format("L");
                    }
                });

                this.setState(state => ({
                    companyName: companyName,
                    startDate: startDate,
                    subscriptionPeriod: subscriptionPeriod,
                    totalUsers: totalUsers,
                    existedUsers: existedUsers,
                    employeeArray: allClients
                }));
            } else {
                let allEmployees = res.data.employees;

                allEmployees && allEmployees.forEach(element => {
                    let formattedNumber = "";
                    if (element.phoneNumber && element.phoneNumber.length > 0) {
                        formattedNumber = formatPhoneNumberIntl(element.phoneNumber);
                    }
                    element.phoneNumber = formattedNumber.length > 0 ? formattedNumber : element.phoneNumber;
                    element.isDirty = DirtyState.FALSE;
                    element.invalidData = {};
                    if (element.dateOfBirth) {                        
                        const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
                        const date = new Date(element.dateOfBirth);                        
                        element.dateOfBirth = formatter.format(date);
                    }
                });

                this.setState(state => ({
                    companyName: companyName,
                    startDate: startDate,
                    subscriptionPeriod: subscriptionPeriod,
                    totalUsers: totalUsers,
                    existedUsers: existedUsers,
                    employeeArray: allEmployees
                }));
            }

            // Initially sort list based on application signup status and field errors.
            let sortable = this.state.sortableColumnStates;
            sortable[SortableColumns.FIRST_NAME] = SortableColumnState.DESCENDING;
            this.setState({
                sortableColumnStates: sortable,
            });
            this.sortColumns(SortableColumns.FIRST_NAME);
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };
    
    postEmployee = (user, isClient) => {
        const jsonArray = JSON.parse(JSON.stringify([user]));
        const suffix = isClient ? "clients" : "employees";

        this.setState({
            errorList: [],
            fetching: this.state.fetching + 1
        });
        axios.post(`${API_URL}/${suffix}`,
            jsonArray, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            })
            .then(res => {

                const responseUsers = isClient ? res.data.clients : res.data.employees;
                if (responseUsers.length !== 1) {
                    throw new Error("Something went wrong.");
                }

                const employeeUuid = responseUsers[0].uuid;
                this.getEmployees();
                this.setState(prevState => ({
                    userFormData: {
                        ...prevState.userFormData,
                        uuid: employeeUuid
                    },
                    fetching: this.state.fetching - 1,
                    showUserDetailsFormModal: false,
                    showUserFormSuccessModal: true
                }));
            })
            .catch(error => {
                this.postAndPatchErrorCallBack(error);
            });
    };

    patchEmployee = (user) => {
        const suffix = user.isClient ? "clients" : "employees";
        this.setState({
            errorList: [],
            fetching: this.state.fetching + 1
        }, () => {
            axios.put(`${API_URL}/${suffix}/${user.uuid}`,
                user, {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token
                    }
                })
                .then(res => {
                    Toast.success("User updated successfully");
                    this.getEmployees();
                    this.setState({
                        fetching: this.state.fetching - 1,
                        showUserDetailsFormModal: false
                    });
                })
                .catch(error => {
                    this.postAndPatchErrorCallBack(error);
                });
        });
    };
    
    uploadCsvPostEmployees = (data, suffix, modalSetErrorList, modalClose) => {
        let jsonArray = JSON.parse(JSON.stringify(data));

        modalSetErrorList([]);
        this.setState({
            fetchingCsv: this.state.fetchingCsv + 1
        });

        axios.post(`${API_URL}/${suffix}/csv`,
            jsonArray, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            })
            .then(res => {
                modalClose();
                this.getEmployees();
                Toast.success("Import Successful");
            }).catch(error => {
                this.uploadCsvPostEmployeesErrorCallBack(error, modalSetErrorList);
            }).finally(error => {
                this.setState({
                    fetchingCsv: this.state.fetchingCsv - 1,
                });
            }); 
    };
    
    uploadCsvPostEmployeesErrorCallBack(error, modalSetErrorList) {
        if (!error) {
            console.log("Error object doesn't exist");
            Toast.error("Something went wrong", 2000);
            return;
        }

        if (error.message) { /* new Error() object from axios.interceptor, API Response from Throw new Exception\InvalidInputException*/
            if (error.message === "Please add more users") {
                this.handleCloseEditUserListModal();
                this.showSubscriptionCapReachedModal();
                return;
            }  
        } else {
            modalSetErrorList(error);
        }
    }
    
    postAndPatchErrorCallBack(error) {
        this.setState({
            fetching: this.state.fetching - 1
        });

        if (!error) {
            console.log("Error object doesn't exist");
            Toast.error("Something went wrong", 10000);
            return;
        }

        if (error && error.messages) { /** API Response from Input\Employee */
            if (typeof(error.messages) === "string") {
                //errors.push(error.messages);
                this.setState({
                    errorList: error.messages.split("\n")
                });

                return;
            }
        } else if (error.message) { /* new Error() object from axios.interceptor, API Response from Throw new Exception\InvalidInputException*/
            if (error.message === "Please add more users") {
                this.handleCloseUserDetailsFormModal();
                this.showSubscriptionCapReachedModal();
                return;
            }  
        } else if (typeof error === "string") {
            Toast.error(error);
        } else {
            console.log("Uncaught error", error);
            Toast.error("Something went wrong", 10000);
            return;
        }
    }

    showSubscriptionCapReachedModal() {
        //refresh the list coz we still insert users.
        this.getEmployees();

        //display cap reached coz we reached the cap
        return Modal.open(
            "Subscription Cap Reached",
            <div>
                <p>You have used up all available subscriptions. You will need to purchase more in
                    order to add new clients/employees. Alternatively, you can remove an existing
                    user to free up a slot -- however, this does mean that user will no longer be
                    able to use the Me app.</p>
                <p>To add more users simply click <strong>Active Subscriptions</strong> in
                    the navigation panel, or click the button below, and purchase
                    more subscriptions.
                </p>
            </div>,
            () => window.location.href = "/active",
            "Purchase More Users",
            false
        );
    }

    updateEmployee = (user) => {
        this.setState({
            userFormData: {
                formType: "edit",
                uuid: user.uuid,
                firstName: user.firstName,
                lastName: user.lastName,
                isClient: user.isClient,
                phoneNumber: user.phoneNumber,
                department: user.department,
                employmentPosition: user.employmentPosition,
                email: user.email
            }
        }, () => {
            const { newEmployee, errorList } = this.newUser(user, false);
            if (errorList.length === 0) {
                this.patchEmployee(newEmployee);
            } else {
                this.setState({
                    errorList: errorList,
                });
            }
        });
    };

    newUser = (user, isNewUser) => { // both patchEmployee and postEmployees depend on this
        const isClient = user.isClient;
        let errorList = [];
        let newEmployee = {};
        newEmployee["newUser"] = isNewUser;
        newEmployee["isClient"] = isClient;
        newEmployee["firstName"] = user.firstName ? capitalizeString(user.firstName) : null;
        newEmployee["lastName"] = user.lastName ? capitalizeString(user.lastName) : null;
        newEmployee["email"] = user.email;
        newEmployee["phoneNumber"] = user.phoneNumber !== null && user.phoneNumber !== undefined ? user.phoneNumber.replace(/\s+/g, "") : null;
        
        if (newEmployee["phoneNumber"]) {
            newEmployee["phoneNumber"] = this.autoRemoveCountryCodeOnSubmit(newEmployee["phoneNumber"], this.context[UserKeys.COUNTRY_CODE]);
        }

        newEmployee["employmentPosition"] = user.employmentPosition;

        if (!isClient) {
            newEmployee["department"] = user.department;
        }

        if (!isNewUser) {
            newEmployee["uuid"] = user.uuid;
        }

        return { newEmployee, errorList };
    };

    removeCountryCodePrefix(phoneValue, countryCodeToRemove) {
        if (!phoneValue || !countryCodeToRemove) {
            return phoneValue; // Handle empty inputs
        }

        if (phoneValue.length !== countryCodeToRemove.length) { //we only remove autoAssigned country code
            return phoneValue;
        }
      
        if (phoneValue.startsWith(countryCodeToRemove)) {
            return phoneValue.slice(countryCodeToRemove.length);
        }
      
        return phoneValue; // String doesn't start with partToRemove, return original
    }

    autoRemoveCountryCodeOnSubmit(phoneValue, defaultCountry) {
        const countryCode = getCountryCallingCodeWithPlusSign(defaultCountry);
        const phoneTest = this.removeCountryCodePrefix(phoneValue, countryCode);

        return phoneTest;
    }

    saveNew = (user) => {
        this.setState({
            userFormData: {
                formType: "add",
                firstName: user.firstName,
                lastName: user.lastName,
                isClient: user.isClient,
                phoneNumber: user.phoneNumber,
                department: user.department,
                employmentPosition: user.employmentPosition,
                email: user.email
            }
        }, () => {
            let { newEmployee, errorList } = this.newUser(user, true);
            if (errorList.length === 0) {
                this.postEmployee(newEmployee, user.isClient);
            } else {
                this.setState({
                    errorList: errorList,
                });
            }
        });
    };

    sortColumns = (columnIndex) => {
        if (typeof columnIndex === "undefined") {
            return;
        }
        
        const columns = this.state.sortableColumnStates;
        let fieldName = "";

        // Setting all other column values to inactive, this way they will always start as descending when clicked after another column,
        // while any subsequent clicks will toggle between descending and ascending sort.
        for (let i = 0; i <= SortableColumns.USER_STATUS; i++) {
            if (i === columnIndex) {
                let value = columns[columnIndex];

                switch (value) {
                    case SortableColumnState.DESCENDING:
                        value = SortableColumnState.ASCENDING;
                        break;
                    case SortableColumnState.ASCENDING:
                        value = SortableColumnState.DESCENDING;
                        break;
                    case SortableColumnState.INACTIVE:
                    default:
                        value = SortableColumnState.ASCENDING;
                        break;
                }

                columns[columnIndex] = value;
            } else {
                columns[i] = SortableColumnState.INACTIVE;
            }
        }

        // Calling the sort for the particular column affected.
        switch (columnIndex) {
            case SortableColumns.FIRST_NAME:
                fieldName = "firstName";
                break;
            case SortableColumns.LAST_NAME:
                fieldName = "lastName";
                break;
            case SortableColumns.EMAIL:
                fieldName = "email";
                break;
            case SortableColumns.DEPARTMENT:
                fieldName = "department";
                break;
            case SortableColumns.JOB_TITLE:
                fieldName = "employmentPosition";
                break;
            case SortableColumns.USER_STATUS:
            default:
                this.sortStatus(columns[columnIndex]);
                break;
        }

        if (fieldName !== "")
            this.sortField(columns[columnIndex], fieldName);

        this.setState({
            sortableColumnStates: columns
        });
    };

    sortField = (sortableState, fieldName) => {
        const employees = this.state.employeeArray;

        if (sortableState === SortableColumnState.ASCENDING) {
            employees.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1);
        } else {
            employees.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1);
        }

        this.setState({
            employeeArray: employees
        });
    };

    sortStatus = (sortableState) => {
        const employees = this.state.employeeArray;

        if (sortableState === SortableColumnState.ASCENDING) {
            employees.sort((a, b) => {
                let aStatus = a.status ? "ACTIVE" : (a.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(a) ? "ERROR" : "PENDING");
                let bStatus = b.status ? "ACTIVE" : (b.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(b) ? "ERROR" : "PENDING");
                return (aStatus > bStatus) ? 1 : -1;
            });
        } else {
            employees.sort((a, b) => {
                let aStatus = a.status ? "ACTIVE" : (a.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(a) ? "ERROR" : "PENDING");
                let bStatus = b.status ? "ACTIVE" : (b.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(b) ? "ERROR" : "PENDING");
                return (aStatus < bStatus) ? 1 : -1;
            });
        }

        this.setState({
            employeeArray: employees
        });
    };

    setUploadUsers = (uploadUsersArray) => {
        this.setState(state => ({
            uploadUsersArray: uploadUsersArray
        }));
    };

    setIsUserUploadAClient = (bool) => {
        this.setState(state => ({
            isUserUploadAClient: bool
        }));
    };
    
    openUserPermissionModal = (data) => {
        this.setState({ showUserPermissionModal: true });
        this.setState({ permModalData: {
            userUuid: data.uuid,
            userName: data.name,
            userEmail: data.email,
            isClient: data.isClient,
            role: data.role ? data.role : NO_ROLE,
            permissions: data.permissions ? data.permissions : [],
            leadershipRoles: data.leadershipRoles
        } });
    };

    openUserDetailsForm = (data) => {
        const isClient = this.getSuffix() === "employees" ? 0 : 1;
        this.setState({ userFormData: {
            formType: data ? "edit" : "add",
            uuid: data ? data.uuid : "",
            firstName: data ? data.firstName : "",
            lastName: data ? data.lastName : "",
            email: data ? data.email : "",
            isClient: data ? data.isClient : isClient,
            phoneNumber: data ? data.phoneNumber : "",
            employmentPosition: data && data.employmentPosition !== null ? data.employmentPosition : "",
            department: data && data.department !== null ? data.department : ""
        } }, () => this.setState({ showUserDetailsFormModal: true }));
    };

    openAddNewUserOptions = () => {
        this.setState({ isShowAddNewUserOptions: true });
    };

    openUploadUserListModal = () => {
        this.setState({ isShowUploadUserListModal: true });
    };

    openEditUserListModal = (existingUserEmailsResponse) => {
        this.setState({ isShowEditUserListModal: true, existingUserEmailsResponse: existingUserEmailsResponse });
    };

    openSendInviteForm = (data) => {
        this.handleCloseUserFormSuccessModal();
        this.setState({
            inviteFormData: {
                users: data
            },
            showSendInviteFormModal: true
        });
    };

    handleRemoveSelectedUsers = (users) => {
        if (window.confirm("Are you sure you want to delete selected user(s)?")) {
            let suffix = this.getSuffix();

            this.setState({
                fetching: this.state.fetching + 1
            });
            axios.post(`${API_URL}/${suffix}/deleteUsers/`,
                { uuids: users },
                {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token
                    }
                })
                .then(res => {
                    this.setState({
                        fetching: this.state.fetching - 1,    
                        showUserDetailsFormModal: false    
                    });
                    this.getEmployees();                    
                })
                .catch(error => {
                    this.setState({
                        fetching: this.state.fetching - 1
                    });
                    if (error.response) {
                        return Toast.error(error.response.data.error);
                    } else {
                        return Toast.error(error.message);
                    }
                });
        }
    };

    showAccessTokenModal = (email, uuid) => {
        let data = {
            "email": email,
            "uuid": uuid
        };

        this.setState({
            showAccessTokenModal: true,
            userNewToken: data
        });
    };

    handleClosePermissionFormModal = () => {
        this.setState({
            showUserPermissionModal: false
        });
    };

    handleCloseUserDetailsFormModal = () => {
        this.setState({ showUserDetailsFormModal: false, errorList: [] });
    };

    handleCloseUserFormSuccessModal = () => {
        this.setState({ 
            showUserFormSuccessModal: false,
            userFormData: {
                formType: "add",
                uuid: null,
                firstName: null,
                lastName: null,
                email: null,
                phoneNumber: null,
                employmentPosition: null,
                department: null,
                isClient: null
            }
        });
    };

    handleCloseInviteFormModal = () => {
        this.setState({ showSendInviteFormModal: false });
    };

    handleCloseNewUserOptionsModal = () => {
        this.setState({ isShowAddNewUserOptions: false });
    };

    handleCloseUploadUserListModal = () => {
        this.setState({ isShowUploadUserListModal: false });
    };

    handleCloseEditUserListModal = () => {
        this.setState({ isShowEditUserListModal: false });
    };

    formSubmissionSuccessful = () => {
        this.getEmployees();
        this.handleClosePermissionFormModal();
    };

    render() {
        const isClient = this.isShowingClients();

        const { fetching, fetchingCsv, employeeArray, uploadUsersArray, existedUsers, errorList, startDate, subscriptionPeriod,
            showUserPermissionModal, permModalData, showUserDetailsFormModal, userFormData,
            showUserFormSuccessModal, isUserUploadAClient,
            showSendInviteFormModal, inviteFormData, isShowAddNewUserOptions, isShowUploadUserListModal, isShowEditUserListModal, existingUserEmailsResponse, sortableColumnStates
            
        } = this.state;

        return (
            <PageContainer>
                <PageHeadingLabel>
                    My User Lists
                </PageHeadingLabel>

                <div className="row" style={{ maxWidth: "94.5rem" }}>
                    <div className="description" style={{ marginBottom: "1em" }}>
                        Invite Employees, Clients or Support Professionals to your business app here. You can add users manually or <br />
                        use our template to upload a CSV spreadsheet. Users will receive a code via email or sms invite once sent. You <br />
                        can set certain permissions for individual users to best manage your app.
                    </div>

                    { (fetching === 0) &&
                        <div className="field">
                            <label>Filter:</label>
                            <InputSelector style={{ height: 51 }} value={isClient ? PageType.CLIENT : PageType.EMPLOYEE}
                                onChange={ (e) => this.setState({ userType: parseInt(e.target.value), isUserUploadAClient: parseInt(e.target.value) }, () => this.getEmployees()) }
                            >
                                <option value={PageType.EMPLOYEE}>
                                    Employees
                                    ({ isClient ? existedUsers - employeeArray.length : employeeArray.length })
                                </option>
                                <option value={PageType.CLIENT}>
                                    Client
                                    ({ isClient ? employeeArray.length : existedUsers - employeeArray.length })
                                </option>
                            </InputSelector>
                        </div>
                    }
                </div>

                { (fetching > 0) ? <LoadingIndicator /> :
                    <>
                        <UserListsTable 
                            users={employeeArray}
                            isShowingClients={isClient}
                            startDate={startDate}
                            subscriptionPeriod={subscriptionPeriod}
                            openPermissionForm={this.openUserPermissionModal}
                            openUserDetailsForm={this.openUserDetailsForm}
                            openAddNewUserOptions={this.openAddNewUserOptions}
                            sortColumn={(key) => this.sortColumns(SortableColumns[key])}
                            sortableColumnStates={sortableColumnStates}
                            removeUsers={(users) => this.handleRemoveSelectedUsers(users)}
                            openSendInviteForm={(data) => this.openSendInviteForm(data)}
                        />

                        {isShowAddNewUserOptions && 
                            <AddNewUserOptionsModal 
                                showDialog={isShowAddNewUserOptions}
                                openUserDetailsForm={this.openUserDetailsForm}
                                openUploadUserListModal={this.openUploadUserListModal}
                                handleClose={this.handleCloseNewUserOptionsModal}
                            />
                        }

                        {isShowUploadUserListModal && 
                            <UploadUserListModal 
                                showDialog={isShowUploadUserListModal}
                                handleClose={this.handleCloseUploadUserListModal}
                                setUploadUsers={this.setUploadUsers}
                                openEditUserListModal={this.openEditUserListModal}
                                isClient={isClient}
                                setIsClient={this.setIsUserUploadAClient}
                                countryCode={this.context[UserKeys.COUNTRY_CODE]}
                                token={this.props.user.token}
                            />
                        }

                        {isShowEditUserListModal && 
                            <EditUserListModal 
                                showDialog={isShowEditUserListModal}
                                handleClose={this.handleCloseEditUserListModal}
                                openUploadUserListModal={this.openUploadUserListModal}
                                uploadUsers={uploadUsersArray}
                                isClient={isUserUploadAClient}
                                uploadCsvPostEmployees={this.uploadCsvPostEmployees}
                                newUser={this.newUser}
                                token={this.props.user.token}
                                fetchingCsv={fetchingCsv}
                                countryCode={this.context[UserKeys.COUNTRY_CODE]}
                                existingUserEmailsResponse={existingUserEmailsResponse}
                            />
                        }

                        {showUserPermissionModal && <>
                            <UserRoleAndPermissionsForm 
                                showDialog={showUserPermissionModal}
                                handleClose={() => this.handleClosePermissionFormModal()}
                                formSubmissionSuccessful={() => this.formSubmissionSuccessful()}
                                userUuid={permModalData.userUuid}
                                userName={permModalData.userName}
                                userEmail={permModalData.userEmail}
                                userRole={permModalData.role}
                                userPermissions={permModalData.permissions}
                                userLeadershipRoles={permModalData.leadershipRoles}
                                token={this.props.user.token}
                                isClient={permModalData.isClient}
                                hasLeadershipRole={permModalData.leadershipRoles.length > 0}
                            />
                        </>}

                        {showUserDetailsFormModal && <>
                            
                            <UserDetailsForm
                                showDialog={showUserDetailsFormModal}
                                handleClose={() => this.handleCloseUserDetailsFormModal()}
                                user={userFormData}
                                token={this.props.user.token}
                                createNewUser={(data) => this.saveNew(data)}
                                removeUsers={(users) => this.handleRemoveSelectedUsers(users)}
                                updateEmployee={(user) => this.updateEmployee(user)}
                                errorList={errorList}
                                countryCode={this.context[UserKeys.COUNTRY_CODE]}
                            />
                        </>}
                        { showUserFormSuccessModal && <>
                            <UserFormSuccessModal
                                showDialog={showUserFormSuccessModal}
                                handleClose={() => this.handleCloseUserFormSuccessModal()}
                                user={userFormData}
                                openSendInviteForm={this.openSendInviteForm}
                                isUserNewlyCreated={this.state.userFormData.formType === "add"}
                            />
                        </>
                        }
                        {showSendInviteFormModal && <>
                            <SendInviteFormModal
                                showDialog={showSendInviteFormModal}
                                handleClose={() => this.handleCloseInviteFormModal()}
                                users={inviteFormData.users}
                                token={this.props.user.token}
                            />
                        </>}
                    </> 
                }
            </PageContainer>
        );
    }
}

export default withRouter(EmployeeList);