export const customStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid #E9EBEF",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        fontSize: "15px",
        fontWeight: "300 !important",
        fontFamily: "Roboto, Helvetica, sans-serif",
        appearance: "none",
        overflow: "visible",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "15px",
        fontWeight: "300 !important",
        justifyContent: "center",
        verticalAlign: "middle",
        color: "#000",
        overflow: "visible",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (base) => ({
        ...base,
        marginTop: "0.5rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        overflow: "visible",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
        overflow: "visible",
    }),
    valueContainer: (base, { selectProps }) => ({
        ...base,
        overflow: "visible",
        marginLeft: "10px",
        ...(selectProps.isMulti && {
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
        })
    }),
    multiValue: (base) => ({
        ...base,
        flexShrink: 0,
    })
};

export const customErrorStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid red",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        fontSize: "15px",
        fontWeight: "300 !important",
        fontFamily: "Roboto, Helvetica, sans-serif",
        appearance: "none",
        overflow: "visible",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "15px",
        fontWeight: "300 !important",
        justifyContent: "center",
        verticalAlign: "middle",
        color: "#000",
        overflow: "visible",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (base) => ({
        ...base,
        marginTop: "0.5rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        overflow: "visible",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
        overflow: "visible",
    }),
    valueContainer: (base) => ({
        ...base,
        overflow: "visible",
        marginLeft: "10px",
    })
};

export const customDisabledStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid #E9EBEF",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#F0F0F0 0% 0% no-repeat padding-box",
        fontSize: "16px",
        fontFamily: "Roboto, Helvetica, sans-serif",
        fontWeight: "300 !important",
        appearance: "none",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "16px",
        fontWeight: "300 !important",
        justifyContent: "center",
        verticalAlign: "middle"
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (provided) => ({
        marginTop: "1rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
    }),
    singleValue: (base) => ({
        color: "#000",
        paddingLeft: "10px",
    })
};
