import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Text } from "src/components";
import styled from "styled-components";

const ClickableDivBox = styled.div`
    width: 205px;
    height: 364px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E9EBEF;
    border-radius: 15px;
    background-color: #F8F8F8;
    cursor: pointer;
`;

const AdVerticalImageUpload = (props) => {
    const { setFormVerticalAsset, setImageChanged, asset } = props;
    const [image, setImage] = useState(asset || null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (asset) {
            setImage(asset);
        }
    }, [asset]);

    const ChangeAsset = styled.div`
        margin-bottom: 20px;
        background-color: black;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        box-shadow: 0px 3px 6px #00000029;
        width: 166px;
        height: 38px;
        font-size: 14px;
        font-family: "Roboto", "Helvetica", sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        color: white;
        z-index: 1;
        position: absolute;
        align-items: center;
        text-align: center;
        justify-content: center;
        display: flex;
    `;

    const handleFiles = (files) => {
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        const validVideoTypes = ["video/mp4"];
        const maxSizeInBytes = 20 * 1024 * 1024; // 20MB

        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 20MB. Please select a smaller file.");
                return;
            }

            reader.onload = () => {
                if (validImageTypes.includes(file.type)) {
                    setImage(reader.result);
                    setFormVerticalAsset(reader.result);
                    setImageChanged(true);
                } else if (validVideoTypes.includes(file.type)) {
                    const video = document.createElement("video");
                    video.src = URL.createObjectURL(file);
                    video.crossOrigin = "anonymous";
                    video.muted = true;
    
                    video.addEventListener("loadeddata", () => {
                        video.currentTime = 2;
    
                        video.addEventListener("seeked", () => {
                            const canvas = document.createElement("canvas");
                            canvas.width = video.videoWidth;
                            canvas.height = video.videoHeight;
    
                            const context = canvas.getContext("2d");
                            context.drawImage(video, 0, 0, canvas.width, canvas.height);
    
                            const thumbnail = canvas.toDataURL("image/png");
    
                            setImage(thumbnail);
                            setFormVerticalAsset(reader.result);
                            setImageChanged(true);
    
                            URL.revokeObjectURL(video.src);
                        });
                    });
                } else {
                    alert("Invalid file type. Please select an image or video.");
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDropzoneClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFiles(event.dataTransfer.files);
    };

    const handleInputChange = (event) => {
        handleFiles(event.target.files);
    };

    return (
        <div>
            <Text size="16px" align="left" weight="700">
                Upload Vertical Asset (Optional)
            </Text>
            <Text size="14px" color="#612684" align="left">
                Click browse file to upload your image or video.
            </Text>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px" }}>
                <ClickableDivBox
                    onClick={handleDropzoneClick}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .mp4"
                        ref={fileInputRef}
                        onChange={handleInputChange}
                        style={{ display: "none" }}
                    />
                    {image ? (
                        <div style={{ display: "flex", position: "relative", alignItems: "end", justifyContent: "center" }}>
                            <img
                                src={image}
                                alt="Preview"
                                style={{
                                    width: "205px",
                                    height: "364px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #E0E0E0",
                                    borderRadius: "15px",
                                    backgroundColor: "#E8E8E8",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                    objectPosition: "center"
                                }}
                            />
                            <ChangeAsset>
                                Change Image/Video
                            </ChangeAsset>
                        </div>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "140px", height: "37.5px", backgroundColor: "#000", boxShadow: "0px 3px 6px #00000029", borderRadius: "7px", color: "#fff", textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", marginTop: "24px", fontWeight: "400" }}>
                                Browse File
                            </div>
                        </div>
                    )}
                </ClickableDivBox>
            </div>
            <p style={{ fontSize: "14px", color: "#808080", maxHeight: "32px", maxWidth: "313px", textAlign: "center", marginBottom: "60px" }}>
                Supported formats: JPG, PNG, MP4. Max size: 20MB. Aspect Ratio 16:9 - 1920px x 1080px
            </p>
        </div>
    );
};

export default AdVerticalImageUpload;
