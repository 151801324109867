import React from "react";
import { Box, Grid } from "@material-ui/core/";
import { FormField, Text } from "src/components";
import styled from "styled-components";

const StyledText = styled(Text)`
    font-family: Roboto, Helvetica, sans-serif;
    text-align: left;
    padding: ${p => p.padding || 0};
    margin: ${p => p.margin || 0};
`;
const StyledInput = styled(FormField)`
    border: 1px solid #000;
    text-align: left;
    border-radius: 12px;
    padding: 0 1.8125rem;
    font-family: Roboto, Helvetica, sans-serif;
    color: #000 !important;
    font-size: 14px;
`;

const SingleInviteForm = ({ user }) => {

    const singlePerson = {
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        email: user?.email ?? "",
        phoneNumber: user?.phoneNumber ?? ""
    };

    return (
        <Box sx={{ margin: "1.40625rem 3.5625rem 1.59375rem 3.5625rem" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <StyledText weight="700" size="14px">First Name</StyledText>
                    <StyledInput value={ singlePerson.firstName } onChange={() => ""}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledText weight="700" size="14px">Last Name</StyledText>
                    <StyledInput value={ singlePerson.lastName } onChange={() => ""}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledText weight="700" size="14px">Email</StyledText>
                    <StyledInput value={ singlePerson.email } onChange={() => ""}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledText weight="700" size="14px">Mobile</StyledText>
                    <StyledInput value={ singlePerson.phoneNumber } onChange={() => ""} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SingleInviteForm;