import axios from "axios";
import { API_URL } from "src/scenes/App";

class SubscriptionPlan {
    constructor(data) {
        this.name = data.name;
        this.currency = data.currency;
        this.taxFraction = data.taxFraction;
        this.periodNetAmountCents = data.phases[0].periodNetAmountCents;
        this.periodIso8601 = data.phases[0].periodIso8601;
        this.upfrontPeriods = data.phases[0].upfrontPeriods;
        this.timesToPay = data.phases[0].timesToPay;
    }

    /**
     * Get the tax-exclusive price in dollars per user over the entire subscription plan
     * @returns {number}
     */
    getSubtotalPerUser() {
        return this.upfrontPeriods * (this.periodNetAmountCents / 100);
    }

    /**
     * Get the tax-exclusive price in dollars for a given number of users over the entire subscription plan
     * @param {number} numberOfUsers
     * @returns {number}
     */
    getSubtotal(numberOfUsers) {
        return numberOfUsers * this.getSubtotalPerUser();
    }

    /**
     *
     * @param {number} numberOfUsers
     * @returns {number}
     */
    getGst(numberOfUsers) {
        const subtotal = this.getSubtotal(numberOfUsers);
        return subtotal * this.taxFraction;
    }

    getPeriodLowercaseName() {
        switch (this.periodIso8601) {
            case "P1M":
                return "month";
            case "P1D":
                return "day";
            case "PT1H":
                return "hour";
            default:
                return "";
        }
    }

    getPaymentDescription() {
        if (this.periodIso8601 === "P1M" && this.upfrontPeriods === 12) {
            return "Yearly plan, Prepaid";
        }
        if (this.periodIso8601 === "P1D" && this.upfrontPeriods === 7) {
            return "Weekly plan, Prepaid";
        }
        if (this.periodIso8601 === "PT1H" && this.upfrontPeriods === 3) {
            return `${this.upfrontPeriods}-hour plan, Prepaid`;
        }
        return "";
    }

    getDurationString() {
        if (this.periodIso8601 === "P1M") {
            return `${this.upfrontPeriods} Months`;
        }
        if (this.periodIso8601 === "P1D") {
            return `${this.upfrontPeriods} Days`;
        }
        if (this.periodIso8601 === "PT1H") {
            return `${this.upfrontPeriods} Hours`;
        }
        return "";
    }

    getSlashSuffix() {
        if (this.periodIso8601 === "P1M" && this.upfrontPeriods === 12) {
            return "yr";
        }
        if (this.periodIso8601 === "P1D" && this.upfrontPeriods === 7) {
            return "wk";
        }
        if (this.periodIso8601 === "PT1H" && this.upfrontPeriods === 3) {
            return `${this.upfrontPeriods}hrs`;
        }
        return "";
    }
}

export default class SubscriptionCostResponse {
    constructor(data) {
        this.plans = data.plans.map(plan => new SubscriptionPlan(plan));
    }

    static default() {
        return new SubscriptionCostResponse({
            plans: []
        });
    }

    static async get(token) {
        const response = await axios.get(`${API_URL}/company/plans/subscriptions`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return new SubscriptionCostResponse(response.data);
    }

    /**
     * @returns {SubscriptionPlan}
     */
    primaryPlan() {
        return this.plans[0];
    }

    findPlanByName(name) {
        return this.plans.find(plan => plan.name === name);
    }
}
