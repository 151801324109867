import React, { Component } from "react";
import styled, { css } from "styled-components";
import {
    FlexContainer, Button, Text, TwoColumnLayout, PageContainer,
    CardHeadingLabel, ExplainParagraphLabel, PageHeadingLabel, LoadingIndicator, CardList
} from "src/components";
import "@trendmicro/react-radio/dist/react-radio.css";
import { ClientLicenseContext as SubscriptionContext } from "../ClientLicenseContext";
import { FormStep } from "./PurchaseLicense";
import AirwallexForm, { AirwallexProduct } from "src/scenes/Subscription/components/AirwallexForm";
import { convertToCurrency } from "src/utils/currency";
import GreenCheck from "src/img/new/green-check.svg";
import CircleIcon from "src/img/new/circle-regular-24.png";

import { PaymentSelectionButton, PaymentSelectionIcon } from "src/scenes/Subscription/forms/Review";

const Screens = {
    OVERVIEW: "overview",
    PAYMENT: "payment"
};

const LeftText = styled(Text)`
    size: ${p => p.size || "14px"};
    color: ${p => p.color || "#23262D"}; 
    text-align: ${p => p.align || "left"};
    margin: ${p => p.margin || "30px 0"};
    line-height: ${p => p.lineHeight};  
    width: ${p => p.width};
    font-family: ${p => p.family};
    min-width: ${p => p.minWidth};
    max-width: ${p => p.maxWidth};
    font-weight: ${p => p.fontWeight || "normal"}
`;

export const Methods = {
    "EXISTING": "Existing Card",
    "NEW": "New Card"
};

export const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${p => p.height || "50px"};
    align-items: ${p => p.alignItems || "center"};
    margin: ${ p => p.margin };
    background-color: ${p => p.color || "transparent"};
    justify-content: ${ p => p.justifyContent || ""};
    ${props => props.isTopBorder && css`
        border-top: 1px solid black;
    `};
    ${props => props.isBottomBorder && css`
        border-bottom: 1px solid black;
    `};
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
`;

const InlineText = styled.p`
    font-weight: ${p => p.weight || 500 };
    font-size: ${p => p.size || "1rem"};
`;

const StyledText = styled(Text)`
    text-align: left;
    margin: ${ p => p.margin || 0};
    font-size: 1rem;
`;

const Card = styled.div`
    background-color: #EDEDED;
    width: 100%;
    max-width: 26.375rem;
    height: auto;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2.3125rem;
    box-sizing: border-box;
`;

const StyledButton = styled(Button)`
    border-radius: 7px !important;
    width: ${ p => p.width };
    height: 2.3125rem;
    line-height: 100%;
    font-weight: 500;
    font-size: 15px;
    padding: 0 !important;
    margin: ${p => p.margin};
    visibility: ${p => p.display};
`;

class PaymentConfirmation extends Component {
    state = {
        method: Methods.EXISTING,
        screen: Screens.OVERVIEW
    };

    componentDidMount() {
        const { getLicenseCostFromAmount } = this.context;
        getLicenseCostFromAmount();
    }

    static contextType = SubscriptionContext;

    updateMethod = (value) => {
        this.setState({
            method: value
        });
    };

    backStepToPagePurchaseLicense = () => {
        this.context.nextStep(FormStep.PAGE_PURCHASE_LICENSE);
    };

    nextClick = () => {
        this.context.nextStep(FormStep.PAGE_PURCHASE_SUCCESSFUL);
    };

    changeScreen = screen => this.setState({ screen });

    render() {
        //add method and updateValue when using radio buttons back
        const { 
            companyName, abn,
            licenseCount, shortTermCost, gst, total, currency,
            selectedCard,
            token, payUsingExistingCard, existingCardErrorString } = this.context;

        const { method, screen } = this.state;

        if (this.context.getLicenseCostFromAmountLoading) {
            return (
                <PageContainer>
                    <PageHeadingLabel>
                        Payment Confirmation
                    </PageHeadingLabel>
                    <LoadingIndicator />
                </PageContainer>
            );
        }

        return (
            <PageContainer>
                <PageHeadingLabel>
                    Purchase Trial Licenses
                </PageHeadingLabel>
                <ExplainParagraphLabel width="43.125rem" size="0.9375rem">
                    { screen === Screens.OVERVIEW ? 
                        "Here is an overview of your Trial License selection. View your order summary and click proceed to payment to purchase your Trial Licenses."
                        : "Select an existing payment method or add a new payment method for this purchase"

                    }
                    
                </ExplainParagraphLabel>

                { screen === Screens.OVERVIEW ?
                    <TwoColumnLayout
                        marginTop="2rem" height="43.625rem"
                        leftContainerWidth="35.0625rem" rightContainerWidth="28.5rem" 
                        leftContainerPadding="2.8125rem 3.75rem" rightContainerPadding="2.8125rem 0 0 0"
                    >
                        <Price
                            companyName={companyName}
                            licenseCount={licenseCount}
                            shortTermCost={shortTermCost}
                            total={total}
                        />
                        <FlexContainer>
                            <OrderSummary
                                licenseCount={licenseCount}
                                shortTermCost={shortTermCost}
                                gst={gst}
                                currency={currency}
                                total={total}
                                isPaymentConfirmation={false}
                                back={() => this.backStepToPagePurchaseLicense()}
                                changeScreen={() => this.changeScreen(Screens.PAYMENT)}
                            />
                        </FlexContainer>
                    </TwoColumnLayout>
                    :
                    <TwoColumnLayout
                        marginTop="2rem" height="auto" minHeight="43.625rem"
                        leftMaxWidth="28.5rem" rightMaxWidth="29.75rem"
                        leftContainerWidth="100%" rightContainerWidth="100%"
                        leftContainerPadding="2.8125rem 0 0 0"
                        rightContainerPadding="2.8125rem 1.6875rem 1rem 2.125rem"
                    >
                        <OrderSummary
                            licenseCount={licenseCount}
                            shortTermCost={shortTermCost}
                            gst={gst}
                            currency={currency}
                            total={total}
                            isPaymentConfirmation={true}
                            back={() => this.changeScreen(Screens.OVERVIEW)}
                        />
                        <FlexContainer>
                            <CardHeadingLabel margin="0 0 1.375rem 0">Payment Method</CardHeadingLabel>
                            <FlexContainer direction="row" position="relative" marginBottom="1.625rem">
                                <PaymentSelectionButton 
                                    maxWidth="16rem" width="100%"
                                    background={method === Methods.EXISTING ? "#000" : "#FFF"}
                                    margin="0 0.375rem 0 0"
                                    color={method === Methods.EXISTING ? "#FFF" : "#000"}
                                    onClick={() => this.updateMethod(Methods.EXISTING)}
                                >

                                    <PaymentSelectionIcon 
                                        src={method === Methods.EXISTING ? GreenCheck : CircleIcon}
                                    />
                                    
                                    Use an existing credit/debit card
                                </PaymentSelectionButton>
                                <PaymentSelectionButton
                                    width="100%"
                                    maxWidth="9.6rem"
                                    background={method === Methods.NEW ? "#000" : "#FFF"}
                                    color={method === Methods.NEW ? "#FFF" : "#000"}
                                    onClick={() => this.updateMethod(Methods.NEW)}
                                >
                                    <PaymentSelectionIcon 
                                        src={method === Methods.NEW ? GreenCheck : CircleIcon}
                                    />
                                    Add a new card
                                </PaymentSelectionButton>
                            </FlexContainer>
                            { method === Methods.NEW ?
                                <FlexContainer>
                                    <AirwallexForm
                                        token={token}
                                        productType={AirwallexProduct.CLIENT_LICENSES}
                                        companyName={companyName} abn={abn} numberOfUsers={licenseCount}
                                        backToPrevious={ this.backStepToPagePurchaseLicense }
                                        nextClick={ this.nextClick }
                                    />
                                </FlexContainer>
                                :
                                <CardList
                                    token={token}
                                    selectedCard={selectedCard}
                                    loadingError={existingCardErrorString}
                                    onBackClicked={() => this.backStepToPagePurchaseLicense()}
                                    onPurchaseClicked={payUsingExistingCard}
                                    onDeleteClicked={null}
                                />

                            }
                        </FlexContainer>
                    </TwoColumnLayout>
                }
            </PageContainer>
        );
    }
}

class Price extends Component {
    render() {
        const { companyName, licenseCount } = this.props;

        return (
            <>
                <CardHeadingLabel>
                Overview
                </CardHeadingLabel>
                <StyledText weight="700" margin="2.1875rem 0 0 0">Business Name:</StyledText>
                <StyledText margin="0 0 0.9375rem 0">{companyName}</StyledText>

                <StyledText weight="700">Number Of Trial Licenses:</StyledText>
                <StyledText margin="0 0 1.1875rem 0">{parseInt(licenseCount).toLocaleString("en-us")}</StyledText>

                <LeftText color="#8E4EB4" margin="0" maxWidth="21.875rem">
                *Purchased Licenses will be added to your business Sales & Promotions feature for use.
                </LeftText>
            </>
        );
    }
}

const OrderSummary = (props) => {
    const { licenseCount, shortTermCost, gst, total, currency, back, changeScreen, isPaymentConfirmation } = props;

    const backToPrevious = () => back();
    const handleChangeScreen = () => changeScreen();
    
    return (
        <FlexContainer justifyContent="start">
            <CardHeadingLabel margin="0 0 0 3.1875rem">Order Summary</CardHeadingLabel>
            <TextContainer style={{ padding: "0 3.5rem 0 3.1875rem" }}>
                <LeftText>
                    <InlineText>Trial License Duration</InlineText>
                    <InlineText>Number of Trial Licenses</InlineText>
                    <InlineText>Price Per Trial License</InlineText>
                </LeftText>
                <LeftText align="right">
                    <InlineText weight="700">12 Months</InlineText>
                    <InlineText weight="700">{licenseCount}</InlineText>
                    <InlineText weight="700">
                        {convertToCurrency(shortTermCost)}
                    </InlineText>
                </LeftText>
            </TextContainer>

            <Card>
                <LeftText margin="0">
                    <InlineText>Subtotal</InlineText>
                    { gst > 0 && <InlineText>GST</InlineText> }
                    <InlineText weight="700" size="1.25rem">Total</InlineText>
                </LeftText>
                <LeftText margin="0" align="right">
                    <InlineText>
                        {convertToCurrency(licenseCount * shortTermCost)}
                    </InlineText>
                    { gst > 0 &&
                        <InlineText>
                            {convertToCurrency(gst)}
                        </InlineText>
                    }
                    
                    <InlineText weight="700" size="1.25rem">
                        {currency} {convertToCurrency(total)}
                    </InlineText>
                </LeftText>
            </Card>

            <FlexContainer direction="row" marginTop="11.25rem" 
                justifyContent={isPaymentConfirmation ? "flex-start" : "flex-end"}
                style={{ marginLeft: isPaymentConfirmation ? "1.75rem" : "" }}
            >
                <StyledButton margin="0 0.875rem 0 0" width="8.75rem" border="1px solid #000000" color="#000000"
                    onClick={() => backToPrevious()}
                >
                    Back
                </StyledButton>
                <StyledButton margin="0 3.25rem 0 0" width="11.125rem" color="#ffffff" backgroundColor="#000000"
                    onClick={() => handleChangeScreen()} display={isPaymentConfirmation ? "hidden" : "visible"}
                >
                    Proceed to Payment
                </StyledButton>
            </FlexContainer>
        </FlexContainer>
    );
};

export default PaymentConfirmation;