import axios from "axios";
import { API_URL } from "src/scenes/App";

export const updateFeatureLevel = async (featureLevel, userTimeZone, token) => {
    try {
        const response = await axios.put(`${API_URL}/employee/appEnvironment`, {
            featureLevel: featureLevel,
            timezone: userTimeZone
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return response.data;
    } catch (e) {
        return console.warn("Unable to request update for user feature level.", e);
    }
};
