import styled, { css } from "styled-components";

// Styled Components
export const TextArea = styled.textarea`
    font-family: Roboto, Helvetica, sans-serif;
    color: #000000;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    height: 180px;
    border: 1px solid #E9EBEF;
    padding: 10px 20px;
    border-radius: 12px;
    text-align: left;
    ::placeholder {
        color: #808080;
        text-align: left;
        font-size 16px;
    }
    font-weight: 300;
    &:focus {
        outline: none;
        border: 1px solid #000;
    };
    caret-color: #000;
    resize: none;
    ${ p => p.isHighlighted && css`
        border: 1px solid red !important;
    `};
    `;

export const PhoneContainer = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    width: 45%;
    justify-content: center;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const FormGrid = styled.div`
    display: grid;
    padding: 0px 50px 20px 50px;
    @media (min-width: 1001px) {
        grid-template-columns: 35% 65%;
    };
    @media (max-width: 1000px) {
        grid-template-columns: 100%;
    };
`;

export const FormLeftSection = styled.div`
    width: auto;
    padding-right: 40px;
    border-bottom: 1px solid #D8D8D8;
    @media (min-width: 1001px) {
        border-right: 1px solid #D8D8D8;
    };
    @media (max-width: 600px) {
        padding-right: unset !important;
    };
`;

export const FormRightSection = styled.div`
    width: auto;
`;

export const FormRightTopSection = styled.div`
    padding-left: 35px;
    @media (max-width: 1000px) {
        padding-top: 20px;
        padding-left: 0 !important;
    };
`;

export const FormAssetSection = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: 1001px) {
        column-gap: 56px;
    };
    @media (max-width: 1000px) {
        column-gap: 20px;
    };
`;

export const FormRightBottomSection = styled.div`
    border-top: 1px solid #D8D8D8;
    height: 850px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
`;