/**
 * to add commas to price
 * @param {number} num
 * @param {string} requestedCurrency
 * @returns string
 */
export const convertToCurrency = (num, requestedCurrency) => {
    const currency = requestedCurrency ?? "USD";
    const roundedNum = num.toFixed(2);
    return parseFloat(roundedNum).toLocaleString("en-US", { style: "currency", currency: currency });
};
