import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

const getUsers = (token) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const [users, setUsers] = useState([]);
    const [isFetchingUsers, setIsFetchingUsers] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            setIsFetchingUsers(true);

            try {
                const config = {
                    signal: controller.signal,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${API_URL}/perks/gift/recipients`, config);

                if (isMountedRef.current) {
                    setUsers(response.data.recipients);
                }
            } catch (error) {
                if (isMountedRef.current) {
                    Toast.error("Unable to fetch gift perk users. Please try again later or contact support if the issue persists.");
                }
            } finally {
                if (isMountedRef.current) {
                    setIsFetchingUsers(false);
                }
            }
        };

        fetch();

        return () => {
            controller.abort();
            isMountedRef.current = false;
        };
    }, [token]);

    return { users, isFetchingUsers };
};

export default getUsers;