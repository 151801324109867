import React, { useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import AdvertisingCard from "./AdvertisingCard";
import { Button } from "src/components";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import { FILTER_BY } from "../Advertising";
import { AdContext } from "../AdContext";

const GridContainer = styled.div`
    display: flex;
    padding: 1rem 0rem 1.5rem 1rem;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 18px;
    background: ${ p => p.isActive ? "#EDF4FF 0% 0% no-repeat padding-box" : "inherit" };
    margin-bottom: 1em;
`;

const DateLabel = styled.div`
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
    
`;

const GridItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 5px;
    flex-basis: calc(100% / 12 * ${({ xs }) => xs});
`;

const HeaderItem = styled.div`
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 600;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 16px;
    padding-bottom: 10px;
    color: #000000;
`;

const EditButton = styled(Button)`
    border-radius: 10px !important;
    width: 160px;
    padding: 11px 16px !important;
    color: #000000;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    border: 1px solid #000000;
`;

const DisplayViews = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Roboto, Helvetica, sans-serif;
    border: 1px solid #2D3037;
    height: 39px;
    width: 160px;
    cursor: pointer;
    letter-spacing: 0.35px;
    align-items: center;
    color: white;
    background: #000000;
    border-radius: 7px;
    color: #FFFFFF;
    font-size: 14px;
`;

const DisplayClicks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Roboto, Helvetica, sans-serif;
    border: 1px solid #2D3037;
    height: 39px;
    width: 160px;
    cursor: pointer;
    letter-spacing: 0.35px;
    align-items: center;
    color: white;
    background: #000000;
    border-radius: 7px;
    color: #FFFFFF;
    font-size: 16px;
`;

const DatePostedLabel = ({ status }) => {
    if (status === FILTER_BY.ACTIVE_ADS) {
        return <div style={{ color: "#000000" }}>Date Posted</div>;
    } else if (status === FILTER_BY.SCHEDULED_ADS) {
        return <div style={{ color: "#006CFF" }}>Scheduled</div>;
    } else if (status === FILTER_BY.COMPLETED_ADS) {
        return <div style={{ color: "#000000" }}>Date Published</div>;
    }
};

const DatePostedContent = ({ dateToUse }) => {
    return (
        <div>
            {moment(dateToUse).format("Do MMMM YYYY")}
            <br/>
            {moment(dateToUse).format("hh:mma")}
        </div>
    );
};

function AdvertisingContent({ advertisingArray, checkedAds, handleCheckboxChange, handleShowAdsViewershipModal, handleEditAd }) {
    const { handleShowAdvertisementViewershipModal, handleShowAdvertisementClicksModal } = useContext(AdContext);
    return advertisingArray.map((ad, index) => {
        const isActive = checkedAds.includes(ad.uuid);
        return (
            <GridContainer key={ index } isActive={isActive} >
                <GridItem xs={0.25}>
                    <HeaderItem>
                        <label>
                            <Checkbox
                                readOnly={false}
                                checked={isActive}
                                onChange={() => handleCheckboxChange(ad.uuid)}
                            />
                        </label>
                    </HeaderItem>
                </GridItem>
                <GridItem xs={1.25}>
                    <HeaderItem>
                        <DatePostedLabel status={ad.status} /> 
                    </HeaderItem>   
                    <div style={{ display: "flex", height: "80%", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <DateLabel>
                            <DatePostedContent dateToUse={ad.startsAt} />
                            { ad.status === FILTER_BY.COMPLETED_ADS &&
                                <div style={{ marginTop: "15px" }}>
                                    <HeaderItem>
                                        Date Ended
                                    </HeaderItem>
                                    <DatePostedContent dateToUse={ad.endsAt} />
                                </div>
                            }
                        </DateLabel>
                    </div>
                </GridItem>
                <GridItem xs={2.5}>
                    <HeaderItem>
                        <div>Headline</div>
                    </HeaderItem>
                    <AdvertisingCard content={ad.headline} ></AdvertisingCard>
                </GridItem>
                <GridItem xs={4}>
                    <HeaderItem>
                        <div>Description</div>
                    </HeaderItem>
                    <AdvertisingCard content={ad.description} ></AdvertisingCard>
                </GridItem>
                <GridItem xs={2.5}>
                    <HeaderItem>
                        <div>{ad.primaryAsset.assetType === "image" ? "Image" : "Video" }</div>
                    </HeaderItem>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "250px",
                        height: "140px",
                        borderRadius: "15px",
                        border: "1px solid #8291B2",
                        overflow: "hidden"
                    }}>
                        <AdAssetPreview ad={ad}/>
                    </div>
                </GridItem>


                <GridItem xs={1.5}>
                    <HeaderItem>Ad Insights</HeaderItem>
                    <div style={{ display: "flex", height: "140px", flexDirection: "column", justifyContent: "space-around", alignItems: "flex-start" }}>
                        <DisplayViews onClick={() => handleShowAdvertisementViewershipModal(ad)} >{ad.totalViews} Impressions</DisplayViews>
                        <DisplayClicks onClick={() => handleShowAdvertisementClicksModal(ad)} >{ad.totalClicks} Clicks</DisplayClicks>
                        { ad.status !== FILTER_BY.COMPLETED_ADS &&
                            <EditButton onClick={() => handleEditAd(ad)} >View/Edit Ad</EditButton>
                        }
                    </div>
                </GridItem>
            </GridContainer>
        );
    });
}

function AdAssetPreview({ ad }) {
    return (
        <>
            {
                ad.primaryAsset.assetType === "image" &&
                <img 
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}
                    src={ad.primaryAsset.assetUrl} 
                    alt={ad.headline}
                />
            }
            
            { /* Render video content here */
                ad.primaryAsset.assetType === "video" &&
                <video
                    controls
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}
                >
                    <source src={ad.primaryAsset.assetUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            }
        </>
    );
}
export default AdvertisingContent;