
import { ENVIRONMENT } from "src/scenes/App";

//const neverReady = () => false; // Useful for testing the production experience on localhost
const notProductionReady = () => ENVIRONMENT.get() !== ENVIRONMENT.PRODUCTION;

export const IsFeatureReady = {
    signUpButton: notProductionReady,
    chat: notProductionReady,
    interactionRateStat: notProductionReady,
    noticeboardAttachments: notProductionReady,
    clientQuoteCategorySelection: notProductionReady,
    advertising: notProductionReady
};
