import React from "react";
import styled from "styled-components";
import { FlexContainer } from "src/components";

const TextDescription = styled.div`
    text-align: left;
    font-size: 15px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: normal;
    color: #000000;
`;

const Card = styled.div`
    width: 100%;
    min-height: 140px;
    max-height: 140px; 
    overflow-y: hidden;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #8291B2;
    border-radius: 18px;
    object-fit: contain;
`;

const AdvertisingCard = (props) => {
    const { content, ...otherProps } = props;

    function truncateString(str, maxLength) {
        return (str.length <= maxLength) ? str : str.substring(0, maxLength - 3) + "...";
    }

    return (
        <Card {...otherProps}>
            <FlexContainer 
                style={{ padding: "1em" }}>
                <TextDescription>{truncateString(content, 120)}</TextDescription>
            </FlexContainer>
        </Card>
    );
};
export default AdvertisingCard;